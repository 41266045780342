import React, { Fragment, useState, useEffect } from 'react';
import Spinner from '../layout/Spinner';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { getlogs } from '../../actions/auth';

import ReactPaginate from 'react-paginate';

const Logs = ({ getlogs, log: { logs, loading } }) => {
	useEffect(() => {
		getlogs();
	}, [getlogs]);

	const [pageNumber, setPageNumber] = useState(0);
	const contentPerPage = 50;
	const pagesVisited = pageNumber * contentPerPage;
	const changePage = ({ selected }) => {
		setPageNumber(selected);
	};

	return (
		<Fragment>
			{loading ? (
				<Spinner />
			) : (
				<div className='col-sm-9 col-sm-offset-3 col-lg-10 col-lg-offset-2'>
					<div>
						<div className='row'>
							<div className='col-lg-12'>
								<h1 className='page-header'>System Logs</h1>
							</div>
						</div>
						{/*/.row*/}
						<div className='panel panel-container '>
							<container className='container-fluid'>
								<div className='table-responsive'>
									<table className='table table-sm table-striped table-hover'>
										<tbody>
											{logs.length > 0
												? logs
														.slice(pagesVisited, pagesVisited + contentPerPage)
														.map((log) => (
															<tr key={log._id}>
																<td className='text-left'>
																	{moment(log.createdAt).format(
																		'YYYY-MM-DD hh:mm'
																	)}{' '}
																	{log.by} {log.message}
																</td>
															</tr>
														))
												: 'Logs not found'}
										</tbody>
									</table>
								</div>
							</container>
						</div>
						<ReactPaginate
							previousLabel={'Previous'}
							nextLabel={'Next'}
							pageCount={Math.ceil(logs.length / contentPerPage)}
							onPageChange={changePage}
							containerClassName={'paginationButtons'}
							previousLinkClassName={'previousButtons'}
							nextLinkClassName={'nextButtons'}
							disabledClassName={'paginationDisable'}
							activeClassName={'paginationActive'}
						/>
					</div>
				</div>
			)}
		</Fragment>
	);
};

Logs.propTypes = {
	getlogs: PropTypes.func.isRequired,
	log: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	log: state.log,
});

export default connect(mapStateToProps, {
	getlogs,
})(Logs);
