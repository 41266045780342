/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { Link } from 'react-router-dom';

export const Navbar = () => {
	return (
		<nav className='navbar navbar-default main_navbar'>
			<div className='container-fluid'>
				<div className='navbar-header'>
					<Link className='navbar-brand navbar-link logo_img' to='/'>
						<img
							className='img-responsive'
							alt='logo'
							src='../../../assets/img/logo.png'
						/>
					</Link>
					<button
						className='navbar-toggle collapsed'
						data-toggle='collapse'
						data-target='#navcol-1'
					>
						<span className='sr-only'>Toggle navigation</span>
						<span className='icon-bar' />
						<span className='icon-bar' />
						<span className='icon-bar' />
					</button>
				</div>
				<div className='collapse navbar-collapse' id='navcol-1'>
					<ul className='nav navbar-nav navbar-right'>
						<li className='dropdown'>
							<a
								className='dropdown-toggle'
								data-toggle='dropdown'
								aria-expanded='false'
								href="/#"
							>
								<button
									className='btn btn-default btn-sm btn-cstm'
									type='button'
									href='packages'
								>
									Packages{' '}
								</button>
								<span className='caret' />
							</a>
							<ul className='dropdown-menu' role='menu'>
								<li role='presentation'>
									<Link to='packages'>All Packages </Link>
								</li>
							</ul>
						</li>
						<li role='presentation'>
							<Link to='support'>Support </Link>
						</li>
						<li role='presentation'>
							<Link to='about_us'>About Us</Link>
						</li>
						<li role='presentation'>
							<Link to='contact_us'>Contact Us</Link>
						</li>
						<li role='presentation'>
							<Link to='faq'>FAQ </Link>
						</li>
						<li role='presentation'>
							<Link to='referral'>Refer a Friend</Link>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	);
};
