import {
	GET_REFERRALS,
	REFERRAL_ERROR,
	GET_REFERRAL,
	REFERRAL_DELETED,
	REFERRAL_UPDATED,
} from '../actions/types';

const initialState = {
	referral: null,
	referrals: [],
	loading: true,
	error: {},
};

function referral(state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case GET_REFERRALS:
			return {
				...state,
				referrals: payload,
				loading: false,
			};
		case GET_REFERRAL:
			return {
				...state,
				referral: payload,
				loading: false,
			};
		case REFERRAL_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
			};
		case REFERRAL_UPDATED:
			return {
				...state,

				referrals: state.referrals.map((referral) =>
					referral._id === payload.referralid
						? { ...referral, status: payload.book.status }
						: referral
				),
				loading: false,
			};
		case REFERRAL_DELETED:
			return {
				...state,
				referrals: state.referrals.filter(
					(referral) => referral._id !== payload
				),
				loading: false,
			};
		default:
			return state;
	}
}

export default referral;
