import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { testimonialadd } from '../../../actions/testimonial';
import { Link, withRouter } from 'react-router-dom';

const TestimonialAdd = ({ testimonialadd, history }) => {
	const [formData, setFormData] = useState({
		fullname: '',
		information: '',
		profession: '',
		publish: '',
	});

	const { fullname, information, publish, profession } = formData;

	const [file, setFile] = useState('');

	const fileHandle = (e) => {
		setFile(e.target.files[0]);
	};

	const onChange = (e) =>
		setFormData({ ...formData, [e.target.name]: e.target.value });

	const onCheckChange = (e) => {
		setFormData({ ...formData, publish: !publish });
	};

	const onSubmit = (e) => {
		e.preventDefault();
		const fd = new FormData();
		fd.append('image', file);
		fd.append('fullname', fullname);
		fd.append('information', information);
		fd.append('profession', profession);
		fd.append('publish', publish);
		testimonialadd(fd, history);
	};

	return (
		<Fragment>
			<div className='col-sm-9 col-sm-offset-3 col-lg-10 col-lg-offset-2'>
				<div className='content-wrapper'>
					{/* Content Header (Page header) */}
					<section className='content-header'>
						<div className='container-fluid'>
							<div className='row mb-2'>
								<div className='col-sm-6'>
									<h1>Add Testimonial</h1>
								</div>
							</div>
						</div>
						{/* /.container-fluid */}
					</section>
					{/* Main content */}
					<section className='content'>
						<div className='container-fluid'>
							<div className='row'>
								{/* left column */}
								<div className='col-md-12'>
									{/* jquery validation */}
									<div>
										<div className='card-header'>
											<h3 className='card-title'>Fields with * are required</h3>
										</div>
										{/* /.card-header */}
										{/* form start */}
										<form onSubmit={(e) => onSubmit(e)}>
											<div className='card-body'>
												<div className='row'>
													<div className='col-sm-6'>
														{/* text input */}
														<div className='form-group'>
															<label htmlFor='fullname'>Full name *</label>
															<input
																type='text'
																name='fullname'
																value={fullname}
																onChange={(e) => onChange(e)}
																className='form-control'
																id='fullname'
																placeholder='Full Name'
															/>
														</div>
													</div>
												</div>

												<div className='row'>
													<div className='col-sm-6'>
														{/* text input */}
														<div className='form-group'>
															<label htmlFor='profession'>Profession *</label>
															<input
																type='text'
																name='profession'
																value={profession}
																onChange={(e) => onChange(e)}
																className='form-control'
																id='profession'
																placeholder='Enter Profession'
															/>
														</div>
													</div>
												</div>
												<div className='row'>
													<div className='col-sm-6'>
														{/* text input */}
														<div className='form-group'>
															<label htmlFor='information'>Information *</label>
															<textarea
																name='information'
																value={information}
																onChange={(e) => onChange(e)}
																className='form-control'
																id='information'
																placeholder='Enter Msg/Info'
																rows='3'
															></textarea>
														</div>
													</div>
												</div>
												<div className='row'>
													<div className='col-sm-2'>
														{/* text input */}
														<div className='form-group'>
															<label htmlFor='publish'>Publish: </label>{' '}
															<input
																type='checkbox'
																name='publish'
																checked={publish}
																onChange={(e) => onCheckChange(e)}
															/>
														</div>
													</div>
													<div className='col-sm-2'>
														<label className='card-title text-center'>
															Choose Picture *
														</label>

														<input
															type='file'
															className='form-control-file'
															onChange={fileHandle}
														/>
													</div>
												</div>
											</div>
											<div className='card-footer'>
												<button type='submit' className='btn btn-primary'>
													Submit
												</button>
												{'  '}
												<Link
													className=' btn btn-danger'
													to='../../../admin/testimonials'
													role='button'
												>
													Cancel
												</Link>
											</div>
										</form>
									</div>
									{/* /.card */}
								</div>
								{/*/.col (left) */}
								{/* right column */}
								<div className='col-md-6'></div>
								{/*/.col (right) */}
							</div>
							{/* /.row */}
						</div>
						{/* /.container-fluid */}
					</section>
					{/* /.content */}
				</div>
			</div>
		</Fragment>
	);
};

TestimonialAdd.propTypes = {
	testimonialadd: PropTypes.func.isRequired,
};

export default connect(null, { testimonialadd })(withRouter(TestimonialAdd));
