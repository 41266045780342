import React from 'react';
import { Link } from 'react-router-dom';


export const Plan = () => {
	return (
		<div className="plan">
  <div>
    <h1 className="title_section">Choose Your Plan</h1>
    <h4 className="subtitle_section">Tarrif </h4></div>
  <div className="container plan_box">
    <div className="row">
      <div className="col-md-4 col-sm-6 plan_col">
        <div className="card">
          <div className="card-header"><span className="card-header-title">Fiber Internet</span><span className="card-header-subtitle">Easy Surfing</span></div>
          <div className="card-header-icon"><i className="fa fa-wifi" /></div>
          <div className="card-body">
            <ul className="list-unstyled card-header-list">
              <li><i className="glyphicon glyphicon-ok" /> Guaranteed Bandwidth</li>
              <li><i className="glyphicon glyphicon-ok" /> Speed &amp; Reliability</li>
              <li><i className="glyphicon glyphicon-ok" /> No Downtime</li>
              <li><i className="glyphicon glyphicon-ok" /> Affordable</li>
            </ul>
          </div>
          {/* <Link className='btn btn-primary card-header-btn' to='./packages' role='button' type="button">
							Get Started
					</Link> */}
         <Link to='./packages'> <button Link='./packages' className="btn btn-primary card-header-btn" type="button">Get Started</button></Link>
        </div>
      </div>
      <div className="col-md-4 col-sm-6 plan_col">
        <div className="card">
          <div className="card-header"><span className="card-header-title">Fiber Internet + TV</span><span className="card-header-subtitle">Home Comfort</span></div>
          <div className="card-header-icon add"><i className="fa fa-wifi" /></div><span className="card_head_plus">+ </span>
          <div className="card-header-icon add_a"><i className="fa fa-tv" /></div>
          <div className="card-body">
            <ul className="list-unstyled card-header-list">
              <li><i className="glyphicon glyphicon-ok" /> Internet With Prabhu IP Tv</li>
              <li><i className="glyphicon glyphicon-ok" /> No Weather Interruption</li>
              <li><i className="glyphicon glyphicon-ok" /> Premium Channels</li>
              <li><i className="glyphicon glyphicon-ok" /> HD Streaming</li>
            </ul>
            <Link to='./packages'> <button className="btn btn-primary card-header-btn" type="button">Get Started</button></Link>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-sm-6 plan_col">
        <div className="card">
          <div className="card-header"><span className="card-header-title">Wireless Internet</span><span className="card-header-subtitle">Impression </span></div>
          <div className="card-header-icon"><i className="fa fa-tv" /></div>
          <div className="card-body">
            <ul className="list-unstyled card-header-list">
              <li><i className="glyphicon glyphicon-ok" /> Long Distance Connection</li>
              <li><i className="glyphicon glyphicon-ok" /> Free Hotspot</li>
              <li><i className="glyphicon glyphicon-ok" /> Wide Coverage</li>
              <li><i className="glyphicon glyphicon-ok" /> 5 GHz</li>
            </ul>
          </div>
          <Link to='./packages'> <button className="btn btn-primary card-header-btn" type="button">Get Started</button></Link>
        </div>
      </div>
    </div>
  </div>
</div>

	);
};
