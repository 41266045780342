import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getinternetplanbyid, internetplanupdate } from '../../../actions/internetplan';
import Spinner from '../../layout/Spinner';
import { withRouter } from 'react-router-dom';
import {Link } from 'react-router-dom'

const InternetPlanEdit = ({
	getinternetplanbyid,
	internetplanupdate,
	internetplan: { internetplan, loading },
	match,
	history,
}) => {
	const [formData, setFormData] = useState({
		speed: '',
		publish: '',
		sme: ''
	});

	useEffect(() => {
		getinternetplanbyid(match.params.id);

		setFormData({
			speed: loading || !internetplan.speed ? '' : internetplan.speed,			
			publish: loading || !internetplan.publish ? '' : internetplan.publish,
			sme: loading || !internetplan.sme ? '' : internetplan.sme,

		});
	}, [getinternetplanbyid, loading]);

	const { speed,sme,publish} = formData;


	const onChange = e =>
		setFormData({ ...formData, [e.target.name]: e.target.value });

	const onCheckChange = e => {
		setFormData({ ...formData, publish: !publish });
    };
    
    const onCheckSmeChange = e => {
		setFormData({ ...formData, sme: !sme });
	};

	const onSubmit = e => {
		e.preventDefault();
		internetplanupdate(match.params.id, formData, history);
	};

	return loading ? (
		<Spinner />
	) : (
		<Fragment>
			
            <div className="col-sm-9 col-sm-offset-3 col-lg-10 col-lg-offset-2">
			
			<div className='content-wrapper'>
				{/* Content Header (Page header) */}
				<section className='content-header'>
					<div className='container-fluid'>
						<div className='row mb-2'>
							<div className='col-sm-6'>
								<h1>Edit Internet Plan</h1>
							</div>
						</div>
					</div>
					{/* /.container-fluid */}
				</section>
				{/* Main content */}
				<section className='content'>
					<div className='container-fluid'>
						<div className='row'>
							{/* left column */}
							<div className='col-md-12'>
								{/* jquery validation */}
								<div >
									<div className='card-header'>
										<h3 className='card-title'>Fields with * are required</h3>
									</div>
									{/* /.card-header */}
									{/* form start */}
									<form onSubmit={e => onSubmit(e)}>
										<div className='card-body'>
											<div className='row'>
												<div className='col-sm-6'>
													{/* text input */}
													<div className='form-group'>
														<label htmlFor='speed'>
															Speed *
														</label>
														<input
															type='text'
															name='speed'
															value={speed}
															onChange={e => onChange(e)}
                                                            className='form-control'
                                                            id='speed'														
															placeholder='00 Mbps'
															
														/>
													</div>
												</div>

                                                <div className='col-sm-6'>													
													<div className='form-group'>
													<label htmlFor='publish'>Publish: </label>{' '}
													<input
													type='checkbox'
													name='publish'
													checked={publish}
													onChange={e => onCheckChange(e)}
													/>
													</div>
                                                    <br />
                                                    <div className='form-group'>
													<label htmlFor='sme'>SME: </label>{' '}
													<input
													type='checkbox'
													name='sme'
													checked={sme}
													onChange={e => onCheckSmeChange(e)}
													/>
													</div>
												</div>	 												
											</div>                                           
										</div>
										<div className='card-footer'>
											<button type='submit' className='btn btn-primary'>
												Submit
											</button>
											{'  '}
											<Link
												className=' btn btn-danger'
												to='../../../admin/internet_plans'
												role='button'
											>
												Cancel
											</Link>
										</div>
									</form>
								</div>
								{/* /.card */}
							</div>
							{/*/.col (left) */}
							{/* right column */}
							<div className='col-md-6'></div>
							{/*/.col (right) */}
						</div>
						{/* /.row */}
					</div>
					{/* /.container-fluid */}
				</section>
				{/* /.content */}
			</div>
            </div>
		</Fragment>
	);
};

InternetPlanEdit.propTypes = {
	getinternetplanbyid: PropTypes.func.isRequired,
	internetplanupdate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	internetplan: state.internetplan,
});

export default connect(mapStateToProps, { getinternetplanbyid, internetplanupdate })(
	withRouter(InternetPlanEdit)
);
