import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { sendEmail } from '../../actions/form';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';

const Suggestion = ({ sendEmail }) => {
	const [formData, setFormData] = useState({
		name: '',
		mobile: '',
		email: '',
		message: '',
	});
	const reCaptcha = useRef();

	const [isVerified, setIsVerified] = useState(false);
	function gonChange(value) {
		setIsVerified(true);
	}

	const { name, mobile, email, message } = formData;

	const onChange = (e) =>
		setFormData({ ...formData, [e.target.name]: e.target.value });

	const onSubmit = (e) => {
		e.preventDefault();
		if (!isVerified) {
			toast.error('Invalid Captcha');
			return;
		}
		sendEmail(formData);
		setIsVerified(false);
		reCaptcha.current.reset();
		setFormData({
			name: '',
			mobile: '',
			email: '',
			message: '',
		});
	};

	return (
		<div className='about_us_div_form'>
			<form onSubmit={(e) => onSubmit(e)}>
				<h1 className='title_section'>Suggestions </h1>
				<h4 className='subtitle_section'>We'd love to hear from you</h4>
				<input
					type='text'
					name='name'
					value={name}
					onChange={(e) => onChange(e)}
					className='form-control'
					placeholder='Your Full Name'
					required
				/>
				<input
					type='email'
					name='email'
					value={email}
					onChange={(e) => onChange(e)}
					className='form-control'
					inputMode='email'
					placeholder='Your Email Address'
				/>
				<input
					type='number'
					name='mobile'
					value={mobile}
					onChange={(e) => onChange(e)}
					className='form-control'
					placeholder='Contact Number'
					minLength={10}
					inputMode='tel'
					required
				/>
				<textarea
					className='form-control'
					required
					name='message'
					value={message}
					onChange={(e) => onChange(e)}
					placeholder='Your Suggestions'
				/>
				<ReCAPTCHA
					ref={reCaptcha}
					className='captcha_control'
					sitekey='6LdT9D4bAAAAAP1hvSJarS3nDaxWwJY5fx7SgYtR'
					onChange={gonChange}
				/>
				<button className='btn btn-default btn-cstm' type='submit'>
					Submit{' '}
				</button>
			</form>
		</div>
	);
};

Suggestion.propTypes = {
	sendEmail: PropTypes.func.isRequired,
};

export default connect(null, { sendEmail })(Suggestion);
