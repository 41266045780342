import React from 'react';

import Slider from './Slider';
import LModal from './LModal';
import { Services } from './Services';
import Testimonials from './Testimonials';
import { Plan } from './Plan';
import { Line } from './Line';

export const Landing = () => {
	return (
		<>
		<LModal />
		<div>
			<Slider />
			<Line />
			<Plan />
			<Line />
			<Services />
			<Line />
			<Testimonials />
			
		</div>
		</>
	);
};
