import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getPmodals } from '../../actions/modal';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

const LModal = ({ getPmodals, modal: { modals, loading } }) => {
	const [open, setOpen] = React.useState(true);

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		getPmodals();
	}, [getPmodals]);

	return loading ? (
		''
	) : (
		<Fragment>
			{modals.length > 0
				? modals.map((modal) => (
						<Modal open={open} onClose={handleClose}>
							<Grid className='modal1'>
								<img
									src={`${modal.image}`}
									alt='popup modal'
									className='img_modal'
								/>
								<Button className='btn-cstm-modal' onClick={handleClose}>
									X
								</Button>
							</Grid>
						</Modal>
				  ))
				: ''}
		</Fragment>
	);
};

Modal.propTypes = {
	getPmodals: PropTypes.func.isRequired,
	modal: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	modal: state.modal,
});

export default connect(mapStateToProps, { getPmodals })(LModal);
