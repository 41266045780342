import React, { Fragment, useState, useEffect } from 'react';
import Spinner from '../../layout/Spinner';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
	getreferrals,
	referraldelete,
	referralupdate,
} from '../../../actions/referral';

import ReactPaginate from 'react-paginate';

const Referral = ({
	getreferrals,
	referraldelete,
	referralupdate,
	referral: { referrals, loading },
}) => {
	useEffect(() => {
		getreferrals();
	}, [getreferrals]);

	var [sn] = useState(1);

	const [pageNumber, setPageNumber] = useState(0);
	const contentPerPage = 20;
	const pagesVisited = pageNumber * contentPerPage;
	const changePage = ({ selected }) => {
		setPageNumber(selected);
	};

	return (
		<Fragment>
			{loading ? (
				<Spinner />
			) : (
				<div className='col-sm-9 col-sm-offset-3 col-lg-10 col-lg-offset-2'>
					<div>
						<div className='row'>
							<div className='col-lg-12'>
								<h1 className='page-header'>Referrals</h1>
							</div>
						</div>
						{/*/.row*/}
						<div className='panel panel-container'>
							<container className='container-fluid'>
								<div className='table-responsive'>
									<table className='table table-striped table-hover'>
										<thead>
											<tr>
												<th>S.N</th>
												<th>Date</th>
												<th>AName</th>
												<th>AEmail</th>
												<th>AUsername</th>
												<th>AMobile</th>
												<th>RName</th>
												<th>RAddress</th>
												<th>RMobile</th>
												<th>REmail</th>
												<th>Message</th>
												<th>Status</th>
												<th>Action</th>
											</tr>
										</thead>
										<tbody>
											{referrals.length >= 0
												? referrals
														.slice(pagesVisited, pagesVisited + contentPerPage)
														.map((referral) => (
															<tr key={referral._id}>
																<td className='text-left'>{sn++}</td>
																<td className='text-left'>
																	{moment(referral.createdAt).format(
																		'YYYY-MM-DD hh:mm'
																	)}
																</td>
																<td className='text-left'>{referral.auname}</td>
																<td className='text-left'>
																	{referral.auemail}
																</td>
																<td className='text-left'>
																	{referral.auusername}
																</td>
																<td className='text-left'>
																	{referral.aumobile}
																</td>
																<td className='text-left'>{referral.rpname}</td>
																<td className='text-left'>
																	{referral.rpaddress}
																</td>
																<td className='text-left'>
																	{referral.rpmobile}
																</td>
																<td className='text-left'>
																	{referral.rpemail}
																</td>
																<td className='text-left'>
																	{referral.message}
																</td>
																<td className='text-left'>{referral.status}</td>

																<td>
																	{' '}
																	<Link
																		onClick={() =>
																			referralupdate(referral._id, {
																				status: 'confirmed',
																			})
																		}
																	>
																		{' '}
																		Confirmed
																	</Link>{' '}
																	|
																	<Link
																		onClick={() =>
																			referralupdate(referral._id, {
																				status: 'cancelled',
																			})
																		}
																	>
																		{' '}
																		Cancelled
																	</Link>{' '}
																	|
																	<Link
																		onClick={() => referraldelete(referral._id)}
																	>
																		{' '}
																		Delete
																	</Link>
																</td>
															</tr>
														))
												: 'Referral not found'}
										</tbody>
									</table>
								</div>
							</container>
						</div>
						<ReactPaginate
							previousLabel={'Previous'}
							nextLabel={'Next'}
							pageCount={Math.ceil(referrals.length / contentPerPage)}
							onPageChange={changePage}
							containerClassName={'paginationButtons'}
							previousLinkClassName={'previousButtons'}
							nextLinkClassName={'nextButtons'}
							disabledClassName={'paginationDisable'}
							activeClassName={'paginationActive'}
						/>
					</div>
				</div>
			)}
		</Fragment>
	);
};

Referral.propTypes = {
	getreferrals: PropTypes.func.isRequired,
	referraldelete: PropTypes.func.isRequired,
	referralupdate: PropTypes.func.isRequired,
	referral: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	referral: state.referral,
});

export default connect(mapStateToProps, {
	getreferrals,
	referralupdate,
	referraldelete,
})(Referral);
