import { GET_LOGS, LOGS_ERROR } from '../actions/types';

const initialState = {
	log: null,
	logs: [],
	loading: true,
	error: {},
};

function log(state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case GET_LOGS:
			return {
				...state,
				logs: payload,
				loading: false,
			};
		case LOGS_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
			};
		default:
			return state;
	}
}

export default log;
