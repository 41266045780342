import axios from 'axios';
import { GET_INTERNETPLANS, INTERNETPLAN_ERROR,PACKAGE_DELETED, GET_PACKAGE, GET_INTERNETPLAN, INTERNETPLAN_UPDATED, INTERNETPLAN_DELETED } from './types';

// Toast
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();


// get all the published internetplans
export const getPinternetplans = () => async dispatch => {
	try {
		const res = await axios.get('/api/plan/');

		dispatch({
			type: GET_INTERNETPLANS,
			payload: res.data,
		});
	} catch (err) {
		dispatch({
			type: INTERNETPLAN_ERROR,
			payload: { msg: err.response.statusText, status: err.response.status },
		});
	}
};

//create new internetplan
export const internetplanadd = (fd, history) => async dispatch => {
	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	};

	try {
		await axios.post('/api/plan/', fd, config);

		toast.success('Internet Plan Successfully Added');
		history.push('/admin/internet_plans');
	} catch (err) {
		const errors = err.response.data.errors;

		if (errors) {
			errors.forEach(error => toast.error(error.msg));
		}

		dispatch({
			type: INTERNETPLAN_ERROR,
		});
	}
};

//create new package
export const packageadd = (planId,fd, history) => async dispatch => {
	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	};

	try {
		await axios.post(`/api/plan/${planId}/list`, fd, config);

		toast.success('Package Successfully Added');
		history.push(`/admin/internet_plan/${planId}/detail`);
	} catch (err) {
		const errors = err.response.data.errors;

		if (errors) {
			errors.forEach(error => toast.error(error.msg));
		}

		dispatch({
			type: INTERNETPLAN_ERROR,
		});
	}
};

//delete package list
export const packagedelete = (internetplanId , listId) => async dispatch => {
	if (window.confirm('Are you sure? You want to delete ?')) {
		try {
			
			await axios.delete(`/api/plan/${internetplanId}/list/${listId}`);

			dispatch({
				type: PACKAGE_DELETED,
				payload: listId,
			});

			toast.success('Package Successfully Removed');
		} catch (err) {
			const errors = err.response.data.errors;

			if (errors) {
				errors.forEach(error => toast.error(error.msg));
			}

			dispatch({
				type: INTERNETPLAN_ERROR,
			});
		}
	}
};

// get package by id
export const getpackagebyid = (planid,packageid) => async dispatch => {
	try {
		const res = await axios.get(`/api/plan/${planid}/${packageid}`);
		dispatch({
			type: GET_PACKAGE,
			payload: res.data,
		});
	} catch (err) {
		dispatch({
			type: INTERNETPLAN_ERROR,
			payload: { msg: err.response.statusText, status: err.response.status },
		});
	}
};

//update package by id
export const packageupdate = (planid,packageid, formData, history) => async dispatch => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		};

		const res = await axios.put(`/api/plan/${planid}/${packageid}`, formData, config);

		dispatch({
			type: INTERNETPLAN_UPDATED,
			payload: res.data,
		});
		history.push(`/admin/internet_plan/${planid}/detail`);
		toast.success('Package Successfully Updated');
	} catch (err) {
		const errors = err.response.data.errors;

		if (errors) {
			errors.forEach(error => toast.error(error.msg));
		}

		dispatch({
			type: INTERNETPLAN_ERROR,
			payload: { msg: err.response.statusText, status: err.response.status },
		});
	}
};


// get all the internetplans
export const getinternetplans = () => async dispatch => {
	try {
		const res = await axios.get('/api/plan/all');

		dispatch({
			type: GET_INTERNETPLANS,
			payload: res.data,
		});
	} catch (err) {
		dispatch({
			type: INTERNETPLAN_ERROR,
			payload: { msg: err.response.statusText, status: err.response.status },
		});
	}
};


//delete internetplan
export const internetplandelete = internetplanId => async dispatch => {
	if (window.confirm('Are you sure? You want to delete ?')) {
		try {
			await axios.delete(`/api/plan/${internetplanId}`);

			dispatch({
				type: INTERNETPLAN_DELETED,
				payload: internetplanId,
			});

			toast.success('Internet Plan Successfully Removed');
		} catch (err) {
			const errors = err.response.data.errors;

			if (errors) {
				errors.forEach(error => toast.error(error.msg));
			}

			dispatch({
				type: INTERNETPLAN_ERROR,
			});
		}
	}
};

// get internetplan by id
export const getinternetplanbyid = internetplanid => async dispatch => {
	try {
		const res = await axios.get(`/api/plan/${internetplanid}`);
		dispatch({
			type: GET_INTERNETPLAN,
			payload: res.data,
		});
	} catch (err) {
		dispatch({
			type: INTERNETPLAN_ERROR,
			payload: { msg: err.response.statusText, status: err.response.status },
		});
	}
};

//update internetplan by id
export const internetplanupdate = (internetplanid, formData, history) => async dispatch => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		};

		const res = await axios.put(`/api/plan/${internetplanid}`, formData, config);

		dispatch({
			type: INTERNETPLAN_UPDATED,
			payload: res.data,
		});
		history.push('/admin/internet_plans');
		toast.success('Internet Plans Successfully Updated');
	} catch (err) {
		const errors = err.response.data.errors;

		if (errors) {
			errors.forEach(error => toast.error(error.msg));
		}

		dispatch({
			type: INTERNETPLAN_ERROR,
			payload: { msg: err.response.statusText, status: err.response.status },
		});
	}
};