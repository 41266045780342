import React, { Fragment } from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';

import Dashboard from '../admin/Dashboard';
import User from '../admin/user/User';
import UserAdd from '../admin/user/UserAdd';
import UserEdit from '../admin/user/UserEdit';
import Testimonial from '../admin/testimonial/Testimonial';
import TestimonialAdd from '../admin/testimonial/TestimonialAdd';
import TestimonialEdit from '../admin/testimonial/TestimonialEdit';
import Slider from '../admin/slider/Slider';
import SliderAdd from '../admin/slider/SliderAdd';
import SliderEdit from '../admin/slider/SliderEdit';
import Modal from '../admin/modal/Modal';
import ModalAdd from '../admin/modal/ModalAdd';
import ModalEdit from '../admin/modal/ModalEdit';
import InternetPlan from '../admin/internetplan/InternetPlan';
import InternetPlanAdd from '../admin/internetplan/InternetPlanAdd';
import InternetPlanEdit from '../admin/internetplan/InternetPlanEdit';
import InternetPlanDetail from '../admin/internetplan/InternetPlanDetail';
import PackageAdd from '../admin/internetplan/PackageAdd';
import PackageEdit from '../admin/internetplan/PackageEdit';
import Booking from '../admin/booking/Booking';
import Referral from '../admin/referral/Referral';
import ChangePassword from '../admin/ChangePassword';
import Logs from '../admin/Logs';
import Topbar from '../admin/layout/Topbar';
import NotFound from '../layout/NotFound';
import Sidebar from '../admin/layout/Sidebar';

const AdminRoutes = () => {
	return (
		<Fragment>
			<Topbar />
			<Sidebar />
			<div className='main'>
				<Switch>
					<PrivateRoute exact path='*/dashboard' component={Dashboard} />
					<PrivateRoute exact path='*/users' component={User} />
					<PrivateRoute exact path='*/user/add' component={UserAdd} />
					<PrivateRoute exact path='*/user/:id/edit' component={UserEdit} />
					<PrivateRoute exact path='*/bookings' component={Booking} />
					<PrivateRoute exact path='*/referrals' component={Referral} />
					<PrivateRoute
						exact
						path='*/change_password'
						component={ChangePassword}
					/>
					<PrivateRoute exact path='*/logs' component={Logs} />
					<PrivateRoute exact path='*/testimonials' component={Testimonial} />
					<PrivateRoute
						exact
						path='*/testimonial/add'
						component={TestimonialAdd}
					/>
					<PrivateRoute
						exact
						path='*/testimonial/:id/edit'
						component={TestimonialEdit}
					/>
					<PrivateRoute exact path='*/sliders' component={Slider} />
					<PrivateRoute exact path='*/slider/add' component={SliderAdd} />
					<PrivateRoute exact path='*/slider/:id/edit' component={SliderEdit} />
					<PrivateRoute exact path='*/modals' component={Modal} />
					<PrivateRoute exact path='*/modal/add' component={ModalAdd} />
					<PrivateRoute exact path='*/modal/:id/edit' component={ModalEdit} />
					<PrivateRoute
						exact
						path='*/internet_plans'
						component={InternetPlan}
					/>
					<PrivateRoute
						exact
						path='*/internet_plan/add'
						component={InternetPlanAdd}
					/>
					<PrivateRoute
						exact
						path='*/internet_plan/:id/edit'
						component={InternetPlanEdit}
					/>
					<PrivateRoute
						exact
						path='*/internet_plan/:id/detail'
						component={InternetPlanDetail}
					/>
					<PrivateRoute
						exact
						path='*/internet_plan/:id/add'
						component={PackageAdd}
					/>
					<PrivateRoute
						exact
						path='*/internet_plan/:id/:listid'
						component={PackageEdit}
					/>

					<PrivateRoute component={NotFound} />
				</Switch>
			</div>
		</Fragment>
	);
};

export default AdminRoutes;
