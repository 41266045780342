import React from 'react';
import PropTypes from 'prop-types';

const SMEPackageItem = ({
    internetplan,
}) => {

	return (            
         <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="table table-striped table-hover">
                      <thead>
                        <tr className="info">
  <th className="package_t">{internetplan.speed} {' / '} {internetplan.sme ? ' SME Plan ' : ' Home Plan '}</th>
                          <th>Internet Cost</th>
                          <th>Device Charge</th>
                          <th>Wire Charge</th>
                          <th>Installation Charge</th>
                          <th>Security Deposit</th>
                          <th>Total</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {internetplan.list.length > 0 ? 
                        internetplan.list.map(l => (<tr>
                          <td className="package_item">{l.duration}</td>
                          <td>{l.internetcost > 0 ? l.internetcost : 'Free'} </td>
                          <td>{l.onucharge > 0 ? l.onucharge : 'Free'} </td>
                          <td>{l.wirecharge > 0 ? l.wirecharge : 'Free'} </td>
                          <td>{l.installationcharge > 0 ? l.installationcharge : 'Free'} </td>
                          <td>{l.securitydeposit > 0 ? l.securitydeposit : 'Not Needed'} </td>
                          <td className='info total_tbl'>{l.securitydeposit + l.onucharge+l.internetcost+ l.installationcharge + l.wirecharge} </td>                          
                          <td>
                          <a href={`/booking?package=${internetplan.speed} without tv&duration=${l.duration}&price=${l.securitydeposit + l.onucharge+l.internetcost+ l.installationcharge + l.wirecharge}`}>  <button className="btn btn-default btn-sm btn-cstm btn-package" type="button" id={internetplan._id}>Book Now</button></a>
                          </td>
                        </tr>   ))
                          : 'Rate Not Found'}
                        
                        
                      </tbody>
                    </table>
                  </div>
                </div>
              </div> 
	);
};

SMEPackageItem.propTypes = {
    internetplan: PropTypes.object.isRequired,
};

export default SMEPackageItem;
