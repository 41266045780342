import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { packageadd } from '../../../actions/internetplan';
import { Link, withRouter } from 'react-router-dom';

const PackageAdd = ({ packageadd,match, history }) => {
	const [formData, setFormData] = useState({
		duration: '',		
        internetcost: '',		
        iptvcost: '',
        settopboxcharge:'',
        onucharge:'',
        wirecharge:'',
        installationcharge:'',
        securitydeposit:'',    
	});

    const { duration,internetcost,iptvcost,settopboxcharge,onucharge,wirecharge,installationcharge, securitydeposit} = formData;

	const onChange = e =>
		setFormData({ ...formData, [e.target.name]: e.target.value });

	const onSubmit = e => {
		e.preventDefault();
		packageadd(match.params.id,formData, history);
	};

	return (
		<Fragment>
             <div className="col-sm-9 col-sm-offset-3 col-lg-10 col-lg-offset-2">
			
			<div className='content-wrapper'>
				{/* Content Header (Page header) */}
				<section className='content-header'>
					<div className='container-fluid'>
						<div className='row mb-2'>
							<div className='col-sm-6'>
								<h1>Add Package </h1>
							</div>
						</div>
					</div>
					{/* /.container-fluid */}
				</section>
				{/* Main content */}
				<section className='content'>
					<div className='container-fluid'>
						<div className='row'>
							{/* left column */}
							<div className='col-md-12'>
								{/* jquery validation */}
								<div >
									<div className='card-header'>
										<h3 className='card-title'>Fields with * are required</h3>
									</div>
									{/* /.card-header */}
									{/* form start */}
									<form onSubmit={e => onSubmit(e)}>
										<div className='card-body'>
											<div className='row'>
												
                                                <div className='col-sm-6'>
													{/* text input */}
													<div className='form-group'>
														<label htmlFor='duration'>
															Duration *
														</label>

														<select
															name='duration'
															className='form-control'
															required
															onChange={e => onChange(e)}
															 autocomplete="off" id="selectbox"
															
														>
															
															<option selected value={duration}>
																Select Plan
															</option>															
															<option value='1 month'>
															1 month
															</option>
															<option value='3 months'>
															3 months
															</option>
															<option value='6 months'>
															6 months
															</option>
															<option value='12 months'>
															12 months
															</option>			
														</select>													
													</div>
												</div>	
												<div className='col-sm-6'>
													{/* text input */}
													<div className='form-group'>
														<label htmlFor='securitydeposit'>Security Deposit *</label>
														<input
															type='number'
															name='securitydeposit'
															value={securitydeposit}
															onChange={e => onChange(e)}
															className='form-control'
															id='securitydeposit'
															placeholder='Amount'
														/>
													</div>
												</div>											
											</div>								

											<div className='row'>
												<div className='col-sm-6'>
													{/* text input */}
													<div className='form-group'>
														<label htmlFor='internetcost'>Internet Cost *</label>
														<input
															type='number'
															name='internetcost'
															value={internetcost}
															onChange={e => onChange(e)}
															className='form-control'
															id='internetcost'
															placeholder='Amount'
														/>
													</div>
												</div>
												
                                                <div className='col-sm-6'>
													{/* text input */}
													<div className='form-group'>
														<label htmlFor='iptvcost'>IP TV Cost </label>
														<input
															type='number'
															name='iptvcost'
															value={iptvcost}
															onChange={e => onChange(e)}
															className='form-control'
															id='iptvcost'
															placeholder='Amount'
														/>
													</div>
												</div>
											</div>

                                            <div className='row'>
												<div className='col-sm-6'>
													{/* text input */}
													<div className='form-group'>
														<label htmlFor='onucharge'>Onu Charge *</label>
														<input
															type='number'
															name='onucharge'
															value={onucharge}
															onChange={e => onChange(e)}
															className='form-control'
															id='onucharge'
															placeholder='Amount'
														/>
													</div>
												</div>
												
                                                <div className='col-sm-6'>
													{/* text input */}
													<div className='form-group'>
														<label htmlFor='settopboxcharge'>SetTop Box Charge </label>
														<input
															type='number'
															name='settopboxcharge'
															value={settopboxcharge}
															onChange={e => onChange(e)}
															className='form-control'
															id='settopboxcharge'
															placeholder='Amount'
														/>
													</div>
												</div>
											</div>

                                            <div className='row'>
												<div className='col-sm-6'>
													{/* text input */}
													<div className='form-group'>
														<label htmlFor='wirecharge'>Wire Charge *</label>
														<input
															type='number'
															name='wirecharge'
															value={wirecharge}
															onChange={e => onChange(e)}
															className='form-control'
															id='wirecharge'
															placeholder='Amount'
														/>
													</div>
												</div>
												
                                                <div className='col-sm-6'>
													{/* text input */}
													<div className='form-group'>
														<label htmlFor='installationcharge'>Installation Charge *</label>
														<input
															type='number'
															name='installationcharge'
															value={installationcharge}
															onChange={e => onChange(e)}
															className='form-control'
															id='installationcharge'
															placeholder='Amount'
														/>
													</div>
												</div>
											</div>
		
										</div>
										<div className='card-footer'>
											<button type='submit' className='btn btn-primary'>
												Submit
											</button>
											{'  '}
											<Link
												className=' btn btn-danger'
												to={`../../../admin/internet_plan/${match.params.id}/detail`}
												role='button'
											>
												Cancel
											</Link>
										</div>
									</form>
								</div>
								{/* /.card */}
							</div>
							{/*/.col (left) */}
							{/* right column */}
							<div className='col-md-6'></div>
							{/*/.col (right) */}
						</div>
						{/* /.row */}
					</div>
					{/* /.container-fluid */}
				</section>
				{/* /.content */}
			</div>
            </div>
		</Fragment>
	);
};

PackageAdd.propTypes = {
	packageadd: PropTypes.func.isRequired,
};

export default connect(null, { packageadd })(withRouter(PackageAdd));
