import axios from 'axios';
import {
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	USER_LOADED,
	AUTH_ERROR,
	CLEAR_PROFILE,
	LOGOUT_SUCCESS,
	GET_LOGS,
	LOGS_ERROR,
} from './types';
// Toast
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import setAuthToken from '../utils/setAuthToken';

toast.configure();

//Load User
export const loadUser = () => async (dispatch) => {
	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	try {
		const res = await axios.get('/api/auth');

		dispatch({
			type: USER_LOADED,
			payload: res.data,
		});
	} catch (err) {
		dispatch({
			type: AUTH_ERROR,
		});
	}
};

//Login User
export const login =
	({ email, password }) =>
	async (dispatch) => {
		console.log('printing ' + email + password + 'endedd');
		const config = {
			headers: {
				'Content-type': 'application/json',
			},
		};

		const body = JSON.stringify({ email, password });

		try {
			const res = await axios.post('/api/auth/login', body, config);

			dispatch({
				type: LOGIN_SUCCESS,
				payload: res.data,
			});

			dispatch(loadUser());
			toast.success('Login Successful');
		} catch (err) {
			const errors = err.response.data.errors;

			if (errors) {
				errors.forEach((error) => toast.error(error.msg));
			}

			dispatch({
				type: LOGIN_FAIL,
			});
		}
	};

//logout
export const logout = () => async (dispatch) => {
	try {
		await axios.get('/api/auth/logout');
		dispatch({ type: LOGOUT_SUCCESS });
		dispatch({ type: CLEAR_PROFILE });
	} catch (err) {
		dispatch({
			type: AUTH_ERROR,
		});
	}
};

// get the logs
export const getlogs = () => async (dispatch) => {
	try {
		const res = await axios.get('/api/auth/logs');

		dispatch({
			type: GET_LOGS,
			payload: res.data,
		});
	} catch (err) {
		dispatch({
			type: LOGS_ERROR,
			payload: { msg: err.response.statusText, status: err.response.status },
		});
	}
};
