export const GET_TESTIMONIALS = 'GET_TESTIMONIALS';
export const GET_TESTIMONIAL = 'GET_TESTIMONIAL';
export const TESTIMONIAL_ERROR = 'TESTIMONIAL_ERROR';
export const TESTIMONIAL_DELETED = 'TESTIMONIAL_DELETED';
export const TESTIMONIAL_UPDATED = 'TESTIMONIAL_UPDATED';

export const GET_INTERNETPLANS = 'GET_INTERNETPLANS';
export const GET_INTERNETPLAN = 'GET_INTERNETPLAN';
export const INTERNETPLAN_ERROR = 'INTERNETPLAN_ERROR';
export const INTERNETPLAN_DELETED = 'INTERNETPLAN_DELETED';
export const INTERNETPLAN_UPDATED = 'INTERNETPLAN_UPDATED';
export const GET_PACKAGE = 'GET_PACKAGE';
export const PACKAGE_DELETED = 'PACKAGE_DELETED';

export const GET_SLIDERS = 'GET_SLIDERS';
export const GET_SLIDER = 'GET_SLIDER';
export const SLIDER_ERROR = 'SLIDER_ERROR';
export const SLIDER_DELETED = 'SLIDER_DELETED';
export const SLIDER_UPDATED = 'SLIDER_UPDATED';

export const GET_MODALS = 'GET_MODALS';
export const GET_MODAL = 'GET_MODAL';
export const MODAL_ERROR = 'MODAL_ERROR';
export const MODAL_DELETED = 'MODAL_DELETED';
export const MODAL_UPDATED = 'MODAL_UPDATED';

export const GET_LOGS = 'GET_LOGS';
export const LOGS_ERROR = 'LOGS_ERROR';

export const GET_BOOKINGS = 'GET_BOOKINGS';
export const GET_BOOKING = 'GET_BOOKING';
export const BOOKING_ERROR = 'BOOKING_ERROR';
export const BOOKING_DELETED = 'BOOKING_DELETED';
export const BOOKING_UPDATED = 'BOOKING_UPDATED';
export const GET_COUNT = 'GET_COUNT';
export const COUNT_ERROR = 'COUNT_ERROR';

export const GET_REFERRALS = 'GET_REFERRALS';
export const GET_REFERRAL = 'GET_REFERRAL';
export const REFERRAL_ERROR = 'REFERRAL_ERROR';
export const REFERRAL_DELETED = 'REFERRAL_DELETED';
export const REFERRAL_UPDATED = 'REFERRAL_UPDATED';

export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const GET_USERS = 'GET_USERS';
export const GET_USER = 'GET_USER';
export const USER_DELETED = 'USER_DELETED';
export const USER_UPDATED = 'USER_UPDATED';
export const USER_ERROR = 'USER_ERROR';
