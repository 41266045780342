import React, { Fragment, useState, useRef } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { resetPassword } from '../../actions/user';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';

// Toast
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const ResetPassword = ({ resetPassword, isAuthenticated, match }) => {
	const [formData, setFormData] = useState({
		newpassword: '',
		confirmpassword: '',
	});
	const reCaptcha = useRef();
	const [isVerified, setIsVerified] = useState(false);
	function gonChange(value) {
		setIsVerified(true);
	}

	const { newpassword, confirmpassword } = formData;

	const onChange = (e) =>
		setFormData({ ...formData, [e.target.name]: e.target.value });

	const onSubmit = (e) => {
		const token = match.params.token;
		e.preventDefault();
		if (!isVerified) {
			toast.error('Invalid Captcha');
			return;
		}
		if (newpassword === confirmpassword) {
			resetPassword(newpassword, token);
			reCaptcha.current.reset();
			setFormData({ newpassword: '', confirmpassword: '' });
		} else {
			toast.error('Password doesnot match');
			reCaptcha.current.reset();
		}
	};

	//Redirect if logged in
	if (isAuthenticated) {
		return <Redirect to='/dashboard' />;
	}

	return (
		<Fragment>
			<div className='row'>
				<div className='col-xs-10 col-xs-offset-1 col-sm-8 col-sm-offset-2 col-md-4 col-md-offset-4'>
					<div className='forgetpassword-panel panel panel-default'>
						<div className='panel-heading'>Reset Password </div>
						<div className='panel-body'>
							<form onSubmit={(e) => onSubmit(e)}>
								{/* text input */}
								<div className='form-group'>
									<label htmlFor='newpassword'>New Password *</label>

									<input
										type='password'
										className='form-control'
										name='newpassword'
										value={newpassword}
										onChange={(e) => onChange(e)}
										placeholder='Enter new Password'
										required
									/>
								</div>

								{/* text input */}
								<div className='form-group'>
									<label htmlFor='confirmpassword'>
										Confirm New Password *
									</label>
									<input
										type='password'
										className='form-control'
										name='confirmpassword'
										value={confirmpassword}
										onChange={(e) => onChange(e)}
										placeholder='Re-type your new password'
										required
									/>
								</div>

								<ReCAPTCHA
									ref={reCaptcha}
									sitekey='6LdT9D4bAAAAAP1hvSJarS3nDaxWwJY5fx7SgYtR'
									onChange={gonChange}
								/>

								<button type='submit' className='btn btn-primary btn-sm'>
									Submit
								</button>
								<br />
							</form>
							<Link to='../admin-login'>
								{' '}
								<h5>Go to Login </h5>
							</Link>
						</div>
					</div>
				</div>
				{/* /.col*/}
			</div>
		</Fragment>
	);
};

ResetPassword.propTypes = {
	resetPassword: PropTypes.func.isRequired,
	isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { resetPassword })(ResetPassword);
