import axios from 'axios';
import {
	GET_BOOKINGS,
	BOOKING_ERROR,
	GET_COUNT,
	COUNT_ERROR,
	BOOKING_UPDATED,
	BOOKING_DELETED,
} from './types';

// Toast
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure();

//create new booking
export const bookingadd = (fd, history) => async (dispatch) => {
	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	};

	try {
		await axios.post('/api/booking', fd, config);
		toast.success('Your Package Have been Successfully Booked');
		toast.success('You Will Get A  Call Shortly');
		history.push('/packages');
	} catch (err) {
		const errors = err.response.data.errors;

		if (errors) {
			errors.forEach((error) => toast.error(error.msg));
		}

		dispatch({
			type: BOOKING_ERROR,
		});
	}
};

// get all the bookings
export const getbookings = () => async (dispatch) => {
	try {
		const res = await axios.get('/api/booking');

		dispatch({
			type: GET_BOOKINGS,
			payload: res.data,
		});
	} catch (err) {
		dispatch({
			type: BOOKING_ERROR,
			payload: { msg: err.response.statusText, status: err.response.status },
		});
	}
};

//delete booking
export const bookingdelete = (bookingId) => async (dispatch) => {
	if (window.confirm('Are you sure? You want to delete ?')) {
		try {
			await axios.delete(`../api/booking/${bookingId}`);

			dispatch({
				type: BOOKING_DELETED,
				payload: bookingId,
			});

			toast.success('Booking Successfully Removed');
		} catch (err) {
			const errors = err.response.data.errors;

			if (errors) {
				errors.forEach((error) => toast.error(error.msg));
			}

			dispatch({
				type: BOOKING_ERROR,
			});
		}
	}
};

// // get booking by id
// export const getbookingbyid = bookingid => async dispatch => {
// 	try {
// 		const res = await axios.get(`/api/booking/${bookingid}`);
// 		dispatch({
// 			type: GET_BOOKING,
// 			payload: res.data,
// 		});
// 	} catch (err) {
// 		dispatch({
// 			type: BOOKING_ERROR,
// 			payload: { msg: err.response.statusText, status: err.response.status },
// 		});
// 	}
// };

//update booking by id
export const bookingupdate = (bookingid, status) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		};

		const res = await axios.put(`/api/booking/${bookingid}`, status, config);

		dispatch({
			type: BOOKING_UPDATED,
			payload: { bookingid, book: res.data },
		});

		toast.success('Booking Successfully Updated');
	} catch (err) {
		const errors = err.response.data.errors;

		if (errors) {
			errors.forEach((error) => toast.error(error.msg));
		}

		dispatch({
			type: BOOKING_ERROR,
			payload: { msg: err.response.statusText, status: err.response.status },
		});
	}
};

// get bookings count
export const getCount = () => async (dispatch) => {
	try {
		const res = await axios.get(`/api/booking/count/all`);

		dispatch({
			type: GET_COUNT,
			payload: {
				Booking: res.data.pbooking,
				Referral: res.data.preferral,
			},
		});
	} catch (err) {
		dispatch({
			type: COUNT_ERROR,
			payload: { msg: err.response.statusText, status: err.response.status },
		});
	}
};
