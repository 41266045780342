import React from 'react'

const Faq = () => {
    return (
        <div className="container faq_main">
  <div>
    <div className="col-md-12">
      <div>
        <h1 className="title_section">Frequently Asked Questions</h1>
        <h4 className="subtitle_section">( FAQ )</h4></div>
      <p className="txt_highlight"><strong>1. Where is Airson’s Head Office? </strong></p>
      <p>Pokhara-14, Chauthe </p>
      <p>Phone.: 1660-61-52095 </p>
      <p>contact@airson.com.np </p>
      <p className="txt_highlight"><strong>2. What are the services available?</strong> </p>
      <ul style={{listStyleType: 'disc'}}>
        <li>Internet Services</li>
        <li>Wi-Fi Services</li>
        <li>Intranet Services</li>
        <li>Wifi Hotspot Deployment Services and so on.</li>
      </ul>
      <p className="txt_highlight"><strong>3. Will my service get disconnected due to payment issue? </strong></p>
      <p>Yes the customer will get disconnected automatically by our system. And re-activation charges may occur. </p>
      <p className="txt_highlight"><strong>4. What could be the reasons for slow browsing?</strong></p>
      <p>There could be various reasons for slower browsing. It could be either at Client’s side or ISP. However, in our experience, following simple things cause the slow browsing. </p>
      <ul>
        <li>Unsecured WiFi set up</li>
        <li>Wi Fi Password hacked by others </li>
        <li>Sharing the connection to multiple users </li>
        <li>Less throughput of WiFi routers or WiFi routers positioned in wrong spot </li>
        <li>Bandwidth- hogging plugins and app </li>
      </ul>
      <p>Note : If everything is okay at your side then please contact our support department. </p>
      <p className="txt_highlight"><strong>5. How do I upgrade/change my current package? </strong></p>
      <p>To upgrade/ change your current package, you can contact us or via Facebook or website. ​You can also visit your nearest branch office to upgrade or change your current package.You can also pay advance to upgrade/change packages </p>
    </div>
  </div>
</div>

    )
}

export default Faq