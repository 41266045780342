import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { changepassword } from '../../actions/user';
import { Link, withRouter } from 'react-router-dom';

// Toast
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const ChangePassword = ({ changepassword, history }) => {
	const [formData, setFormData] = useState({
		oldpassword: '',
        newpassword: '',
        confirmpassword: '',
	});

	const { oldpassword, newpassword, confirmpassword } = formData;

	const onChange = e => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const onSubmit = e => {
		e.preventDefault();

		if (newpassword !== confirmpassword) {
			toast.error('New Password doesnot match');
		} else {
			changepassword(formData);
		}
		setFormData({
			oldpassword: '',
			newpassword: '',
			confirmpassword: '',
		});
	};


	return (
		<Fragment>
             <div className="col-sm-9 col-sm-offset-3 col-lg-10 col-lg-offset-2">
			
			<div className='content-wrapper'>
				{/* Content Header (Page header) */}
				<section className='content-header'>
					<div className='container-fluid'>
						<div className='row mb-2'>
							<div className='col-sm-6'>
								<h1>Change Password</h1>
							</div>
						</div>
					</div>
					{/* /.container-fluid */}
				</section>
				{/* Main content */}
				<section className='content'>
					<div className='container-fluid'>
						<div className='row'>
							{/* left column */}
							<div className='col-md-12'>
								{/* jquery validation */}
								<div >
									<div className='card-header'>
										<h3 className='card-title'>Fields with * are required</h3>
									</div>
									{/* /.card-header */}
									{/* form start */}
									<form onSubmit={e => onSubmit(e)}>
										<div className='card-body'>
											<div className='row'>
												<div className='col-sm-6'>
													{/* text input */}
													<div className='form-group'>
                                                    <label htmlFor='oldpassword'>Old Password *</label>
									
											<input
												type='password'
												className='form-control'
												name='oldpassword'
												value={oldpassword}
												onChange={e => onChange(e)}
												placeholder='Enter your old password'
												required
											/>
										</div>
													</div>
												</div>
												
											</div>

											<div className='row'>
												<div className='col-sm-6'>
													{/* text input */}
													<div className='form-group'>
                                                    <label htmlFor='newpassword'>New Password *</label>
									
											<input
												type='password'
												className='form-control'
												name='newpassword'
												value={newpassword}
												onChange={e => onChange(e)}
												placeholder='Enter new Password'
												required
											/>
										</div>
													</div>
												</div>												
	
											<div className='row'>
												<div className='col-sm-6'>
													{/* text input */}
													<div className='form-group'>
                                                    <label htmlFor='confirmpassword'>
											Confirm New Password *
										</label>
										<input
											type='password'
											className='form-control'
											name='confirmpassword'
											value={confirmpassword}
											onChange={e => onChange(e)}
											placeholder='Re-type your new password'
											required
										/>
													</div>
												</div>
												
											</div>
									
										<div className='card-footer'>
											<button type='submit' className='btn btn-primary'>
												Change Password
											</button>
											{'  '}
											<Link
												className=' btn btn-danger'
												to='../../../admin/users'
												role='button'
											>
												Cancel
											</Link>
										</div>
									</form>
								</div>
								{/* /.card */}
							</div>
							{/*/.col (left) */}
							{/* right column */}
							<div className='col-md-6'></div>
							{/*/.col (right) */}
						</div>
						{/* /.row */}
					</div>
					{/* /.container-fluid */}
				</section>
				{/* /.content */}
			</div>
            </div>
		</Fragment>
	);
};

ChangePassword.propTypes = {
	changepassword: PropTypes.func.isRequired,
};

export default connect(null, { changepassword })(withRouter(ChangePassword));
