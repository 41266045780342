import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { login } from '../../actions/auth';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';

const Login = ({ login, isAuthenticated }) => {
	const [formData, setFormData] = useState({
		email: '',
		password: '',
	});

	const reCaptcha = useRef();

	const [isVerified, setIsVerified] = useState(false);
	function gonChange(value) {
		setIsVerified(true);
	}

	const { email, password } = formData;

	const onChange = (e) =>
		setFormData({ ...formData, [e.target.name]: e.target.value });

	const onSubmit = async (e) => {
		e.preventDefault();
		if (!isVerified) {
			toast.error('Invalid Captcha');
			return;
		}
		login({ email, password });
		setIsVerified(false);
		reCaptcha.current.reset();
	};

	//Redirect if logged in
	if (isAuthenticated) {
		return <Redirect to='/admin/dashboard' />;
	}

	return (
		<div>
			<div className='row'>
				<div className='col-xs-10 col-xs-offset-1 col-sm-8 col-sm-offset-2 col-md-4 col-md-offset-4'>
					<div className='login-panel panel panel-default'>
						<div className='panel-heading'>Log in</div>
						<div className='panel-body'>
							<form role='form' onSubmit={(e) => onSubmit(e)}>
								<fieldset>
									<div className='form-group'>
										<input
											className='form-control'
											placeholder='E-mail'
											name='email'
											value={email}
											onChange={(e) => onChange(e)}
											type='email'
											required
											autofocus
										/>
									</div>
									<div className='form-group'>
										<input
											className='form-control'
											placeholder='Password'
											name='password'
											value={password}
											onChange={(e) => onChange(e)}
											type='password'
											required
										/>
									</div>
									<ReCAPTCHA
										ref={reCaptcha}
										sitekey='6LdT9D4bAAAAAP1hvSJarS3nDaxWwJY5fx7SgYtR'
										onChange={gonChange}
									/>
									<button type='submit' className='btn btn-primary btn-sm'>
										Login
									</button>
									<br />
								</fieldset>
							</form>
							<Link to='forget_password'>
								{' '}
								<h5> Forgot Password ?</h5>
							</Link>
						</div>
					</div>
				</div>
				{/* /.col*/}
			</div>
			{/* /.row */}
		</div>
	);
};

Login.protoTypes = {
	login: PropTypes.func.isRequired,
	isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(Login);
