import React from 'react';

export const Services = () => {
	return (
		<div className='services'>
			<div>
				<h1 className='title_section'>Our Services</h1>
				<h4 className='subtitle_section'>Get Best From Us</h4>
			</div>
			<div className='container-fluid'>
				<div className='row'>
					<div className='col-md-4 col-sm-4 service_icon'>
						<i className='fa fa-tachometer'></i>
						<h3>
							<span className='service_info'>Ultra Speed </span>Connection
						</h3>
					</div>
					<div className='col-md-4 col-sm-4 service_icon'>
						<i className='material-icons'>tv</i>
						<h3>
							<span className='service_info'>250 + </span>World Channels
						</h3>
					</div>
					<div className='col-md-4 col-sm-4 service_icon'>
						<i className='material-icons'>router</i>
						<h3>
							<span className='service_info'>Free </span>Installation
						</h3>
					</div>
				</div>
				<div className='col-sm-12'>
					<hr />
				</div>
				<div className='row'>
					<div className='col-md-4 col-sm-4 service_icon'>
						<i className='material-icons'>hd</i>
						<h3>
							<span className='service_info'>High </span>Quality
						</h3>
					</div>
					<div className='col-md-4 col-sm-4 service_icon'>
						<i className='material-icons'>insert_chart</i>
						<h3>
							<span className='service_info'>Flexible </span>Tarrif Plans
						</h3>
					</div>
					<div className='col-md-4 col-sm-4 service_icon'>
						<i className='fa fa-support'></i>
						<h3>
							<span className='service_info'>Fast </span>Support
						</h3>
					</div>
				</div>
			</div>
		</div>
	);
};
