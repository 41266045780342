import React from 'react';
import PropTypes from 'prop-types';

const TestimonialItem = ({
	testimonial: { _id, image, fullname, information, profession },
}) => {
	return (
		<div className='item testimonials_slide'>
			{image ? (
				<img
					className='img-circle testimonial_img'
					alt='Slider'
					src={`${image}`}
					width='100'
					height='100'
				/>
			) : (
				<img
					className='img-circle testimonial_img'
					alt='Slider'
					src='assets/img/logo.png'
					width='100'
					height='100'
				/>
			)}

			<div className='carousel-caption testimonials-content'>
				<h4 className='text-uppercase text-center'>{fullname}</h4>
				<p>( {profession} )</p>
				<p className='text-center'>{information}</p>
			</div>
		</div>
	);
};

TestimonialItem.propTypes = {
	testimonial: PropTypes.object.isRequired,
};

export default TestimonialItem;
