import {
	GET_INTERNETPLANS,
	GET_INTERNETPLAN,
	PACKAGE_DELETED,
	INTERNETPLAN_ERROR,
	INTERNETPLAN_DELETED,
	INTERNETPLAN_UPDATED,
} from '../actions/types';

const initialState = {
	internetplan: null,
	internetplans: [],
	loading: true,
	error: {},
};

function internetplan(state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case GET_INTERNETPLANS:
			return {
				...state,
				internetplans: payload,
				loading: false,
			};

		case GET_INTERNETPLAN:
		case INTERNETPLAN_UPDATED:
			return {
				...state,
				internetplan: payload,
				loading: false,
			};
		case INTERNETPLAN_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
			};
		case INTERNETPLAN_DELETED:
			return {
				...state,
				internetplans: state.internetplans.filter(
					(internetplan) => internetplan._id !== payload
				),
				loading: false,
			};
		case PACKAGE_DELETED:
			return {
				...state,
				internetplan: {
					...state.internetplan,
					list: state.internetplan.list.filter((l) => l._id !== payload),
				},
				loading: false,
			};
		default:
			return state;
	}
}

export default internetplan;
