import { combineReducers } from 'redux';
import testimonial from './testimonial';
import slider from './slider';
import modal from './modal';
import auth from './auth';
import internetplan from './internetplan';
import user from './user';
import ipackage from './ipackage';
import booking from './booking';
import referral from './referral';
import count from './count';
import log from './log';

export default combineReducers({
	modal,
	slider,
	testimonial,
	referral,
	booking,
	ipackage,
	user,
	internetplan,
	count,
	auth,
	log,
});
