import axios from 'axios';

// Toast
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

//Send email
export const sendEmail = (fd) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		};
		await axios.post('/api/auth/sendmail', fd, config);
		toast.success('Email Successfully Send');
	} catch (err) {
		// const errors = err.response.data.errors;
		toast.error('Unable to send Email');
	}
};
