import React from 'react'
import Suggestion from '../forms/Suggestion'

const About = () => {
    return (
        <div className="container-fluid about_us_main">
  <div>
    <div className="col-md-8">
      <h1 className="title_section">About Us</h1>
      <h4 className="subtitle_section">Company Profile</h4>
      <h4 className="text-uppercase bg-primary about_us_stitle">Introduction </h4>
      <p className="text-justify">Airson Internet Pvt. Ltd was formed on 7th July, 2020. The company is established to provide the high speed Internet connectivity and communications solutions for business and individuals. </p>
      <p className="text-justify">We are solely focused on FTTH services and our engineers constantly strive to bring the best product and service package according to our customer needs. Airson Internet aims to be the service provider that satisfies the actual customer
        requirements. </p>
      <h4 className="text-uppercase bg-primary about_us_stitle">Mission Statement</h4>
      <p>Airson Internet’s mission is to: </p>
      <ul>
        <li>Provide High Speed Internet Services without any interruption </li>
        <li> Wide Network Coverage </li>
        <li>24 x 7 Customer Support </li>
        <li>Dedicated Speeds </li>
        <li> Uninterrupted Service </li>
      </ul>
      <h4 className="text-uppercase bg-primary about_us_stitle">Vision </h4>
      <p className="text-justify">Our Vision is to furnish quality web and organization administration with higher speed and more prominent dependability. Responsive client care with esteem included help that gives total fulfillment to our client is our thirst and furthermore
        the undertaking of Airson Internet. </p>
      <h4 className="text-uppercase bg-primary about_us_stitle">Mission </h4>
      <p className="text-justify">To grasp the best and demonstrate innovation to convey the internet to our clients. To give the best client assistance and administration, and make the best workplace for our representatives. </p>
      <p className="text-center">- <i className="fa fa-pagelines" /> -</p>
    </div>
    <div className="col-md-4">
      <Suggestion />
      <div className="about_us_div_team">
        <h4><span style={{textDecoration: 'underline'}}>Our Executive Team Members</span></h4>
        <p className="lead text-info abt_l">Chartered Account (CA)</p>
        <p className="text-uppercase">Tulasi Sapkota</p>
        <p className="lead text-info abt_l">System Engineer</p>
        <p className="text-uppercase">Shangharsha tripathi</p>
        <p className="lead text-info abt_l">Network Engineer</p>
        <p className="text-uppercase">Suman lamichhane</p>
      </div>
    </div>
  </div>
</div>

    )
}

export default About