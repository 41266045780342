import React, {Fragment,useState, useEffect} from 'react'
import Spinner from '../../layout/Spinner';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { gettestimonials,testimonialdelete } from '../../../actions/testimonial';

const Testimonial = ({ gettestimonials,testimonialdelete, testimonial: { testimonials, loading } }) => {
  useEffect(() => {
		gettestimonials();
	}, [gettestimonials]);

	var [sn] = useState(1);


    return (
      <Fragment>
      {loading ? (
				<Spinner />
			) : (
        <div className="col-sm-9 col-sm-offset-3 col-lg-10 col-lg-offset-2">
          <div>
  <div className="row">
    <div className="col-lg-12">
      <h1 className="page-header">Testimonials</h1>
    </div>
  </div>{/*/.row*/}
  <div className="panel panel-container">
    <container className="container-fluid">
    <Link
										className='btn btn-default btn-lg'
										to='../../../admin/testimonial/add'
										role='button'
									>
										Add New Testimonial
									</Link>
      <div className="table-responsive">
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>S.N</th>
              <th>Information</th>
              <th>By</th>
              <th>Profession</th>
              <th>Updated Date</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
          {testimonials.length >= 0
													? testimonials.map(testimonial => (
															<tr key={testimonial._id}>																
																<td className='text-left'>{sn++}</td>
																<td className='text-left'>{testimonial.information}</td>															
																<td className='text-left'>{testimonial.fullname}</td>
																<td className='text-left'>{testimonial.profession}</td>
																<td className='text-left'>{moment(testimonial.createdAt).format(
																				'YYYY-MM-DD')}</td>
																<td className='text-left'>{testimonial.publish ? 'Published' : 'Not Published'}</td>
                                
              <td> <a href={`/admin/testimonial/${testimonial._id}/edit`}> Edit </a>  | 
               <Link onClick={() => testimonialdelete(testimonial._id)}>
																	{' '}	Delete
																		</Link>
                                    </td>
															</tr>
													  ))
													: 'Testimonial not found'}
            
          </tbody>
        </table>
      </div>
    </container>
  </div>
</div>

        </div>
        )}
        </Fragment>
    )
}

Testimonial.propTypes = {
  gettestimonials: PropTypes.func.isRequired,
  testimonialdelete:PropTypes.func.isRequired,
	testimonial: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	testimonial: state.testimonial,
});

export default connect(mapStateToProps, { gettestimonials,testimonialdelete})(Testimonial);

