import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from './Spinner';
import SliderItem from './SliderItem';
import {Link} from 'react-router-dom'
import { getPsliders } from '../../actions/slider';

const Slider = ({getPsliders,slider: { sliders, loading }}) => {
	useEffect(() => {
		getPsliders();
	}, [getPsliders]);

	var [slide]= useState(0);

	return loading ? (
		<Spinner />
	) : (
		<Fragment>
			{sliders.length > 0 ? (<div className="carousel slide" data-ride="carousel" id="carousel-1">
  <div className="carousel-inner slider_main" role="listbox">
  {sliders.length > 0
							? sliders.slice(0, 1).map(slider => (
									<div className='item active'>
										<img
											className='img-responsive'
											src={`${slider.image}`}
											alt='Slider'
											width="100%" height="auto"											
										/>
										<div className="carousel-caption carousel-content">
  <h1 className="text-left">{slider.title} </h1>
  <div className="row">
    <div className="col-md-6">
	<p className="text-left">{slider.description}</p>
    </div>
  </div>
	{slider.buttontitle ? (<button className="btn btn-default btn-sm btn-cstm add_btn" type="button"><Link to={slider.buttonlink}>{slider.buttontitle}</Link></button>) : ''}
  
</div>

									</div>
							  ))
							: ''}


  {sliders.length > 1
			? sliders			
			.slice(1, sliders.length)		
					.map(slider => (
						<SliderItem
							key={slider._id}
							slider={slider}

						/>
					))
			: ''}
  </div>
  <div><a className="left carousel-control" href="#carousel-1" role="button" data-slide="prev"><i className="glyphicon glyphicon-chevron-left" /><span className="sr-only">Previous</span></a><a className="right carousel-control" href="#carousel-1" role="button" data-slide="next"><i className="glyphicon glyphicon-chevron-right" /><span className="sr-only">Next</span></a></div>
  <ol className="carousel-indicators">
  {sliders.length > 0
							? sliders.slice(0, 1).map(slider => (
								<li data-target="#carousel-1" data-slide-to={slide} className="active" />
							  ))
							: ''}


  {sliders.length > 1
			? sliders			
			.slice(1, sliders.length)		
					.map(slider => (
						<li data-target="#carousel-1" data-slide-to={slide++} />
					))
			: ''}
  </ol>
</div>) : ''}
		
</Fragment>
	);
};

Slider.propTypes = {
	getPsliders: PropTypes.func.isRequired,
	slider: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	slider: state.slider,
});

export default connect(mapStateToProps, { getPsliders })(Slider);

