import React, { Fragment, useEffect, useState } from 'react';
import Spinner from '../../layout/Spinner';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { userlist,userdelete } from '../../../actions/user';

const User = ({ userlist,userdelete, user: { users, loading } }) => {
  useEffect(() => {
		userlist();
	}, [userlist]);

	var [sn] = useState(1);


    return (
		<Fragment>
      {loading ? (
				<Spinner />
			) : (
        <div className="col-sm-9 col-sm-offset-3 col-lg-10 col-lg-offset-2">

<div>
  <div className="row">
    <div className="col-lg-12">
      <h1 className="page-header">Users</h1>
    </div>
  </div>{/*/.row*/}
  <div className="panel panel-container">
    <container className="container-fluid">
      <div className="be-btn-cstm">

      <Link
										className='btn btn-default btn-lg'
										to='../../../admin/user/add'
										role='button'
									>
										Add New User
									</Link>
      </div>
      <div className="table-responsive">
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>S.N</th>
              <th>Full Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>

          {users.length >= 0
													? users.map(user => (
															<tr key={user._id}>
																
																<td className='text-left'>{sn++}</td>
																<td className='text-left'>{user.name}</td>															
																<td className='text-left'>{user.email}</td>
                                <td>{user.developer ? 'Super Admin' : 'Admin'}</td>
              <td> <a href={`/admin/user/${user._id}/edit`}> Edit</a>  | <Link onClick={() => userdelete(user._id)}>
																		Delete
																		</Link></td>
															</tr>
													  ))
													: 'User not found'}

            
          </tbody>
        </table>
      </div>
    </container>
    {/*/.row*/}
  </div>
</div>

			</div>
			)}
			</Fragment>
    )
}

User.propTypes = {
  userlist: PropTypes.func.isRequired,
  userdelete: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	user: state.user,
});

export default connect(mapStateToProps, { userlist,userdelete })(User);
