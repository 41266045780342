import {
	GET_USERS,
	GET_USER,
	USER_ERROR,
	USER_UPDATED,
	USER_DELETED,
} from '../actions/types';

const initialState = {
	users: [],
	user: null,
	loading: true,
	error: {},
};

function user(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case GET_USERS:
			return {
				...state,
				users: payload,
				loading: false,
			};
		case GET_USER:
		case USER_UPDATED:
			return {
				...state,
				user: payload,
				loading: false,
			};
		case USER_DELETED:
			return {
				...state,
				users: state.users.filter((user) => user._id !== payload),
				loading: false,
			};
		case USER_ERROR:
			return {
				...state,
				users: [...state.users],
				loading: false,
			};
		default:
			return state;
	}
}
export default user;
