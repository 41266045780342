import React,{Fragment,useEffect, useState} from 'react'
import Spinner from '../../layout/Spinner';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { getsliders,sliderdelete } from '../../../actions/slider';


const Slider = ({ getsliders,sliderdelete, slider: { sliders, loading } }) => {
  useEffect(() => {
		getsliders();
	}, [getsliders]);

	var [sn] = useState(1);


    return (
      <Fragment>
      {loading ? (
				<Spinner />
			) : (
        <div className="col-sm-9 col-sm-offset-3 col-lg-10 col-lg-offset-2">
          <div>
  <div className="row">
    <div className="col-lg-12">
      <h1 className="page-header">Sliders</h1>
    </div>
  </div>{/*/.row*/}
  <div className="panel panel-container">
    <container className="container-fluid">
    <Link
										className='btn btn-default btn-lg'
										to='../../../admin/slider/add'
										role='button'
									>
										Add New Slider
									</Link>
      <div className="table-responsive">
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>S.N</th>
              <th>Title</th>
              <th>Description</th>
              <th>Image</th>
              <th>Button Title</th>
              <th>Button Link</th>
              <th>Updated At</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
          {sliders.length >= 0
													? sliders.map(slider => (
															<tr key={slider._id}>																
																<td className='text-left'>{sn++}</td>
																<td className='text-left'>{slider.title}</td>															
																<td className='text-left'>{slider.description}</td>
																<td className='text-left'>{slider.image}</td>
																<td className='text-left'>{slider.buttontitle}</td>
																<td className='text-left'>{slider.buttonlink}</td>
																<td className='text-left'>{moment(slider.updatedAt).format(
																				'YYYY-MM-DD')}</td>
																<td className='text-left'>{slider.publish ? 'Published' : 'Not Published'}</td>
                                
              <td> <a href={`/admin/slider/${slider._id}/edit`}> Edit </a>  | 
               <Link onClick={() => sliderdelete(slider._id)}>
																	{' '}	Delete
																		</Link>
                                    </td>
															</tr>
													  ))
													: 'Slider not found'}
          </tbody>
        </table>
      </div>
    </container>
    {/*/.row*/}
  </div>
</div>

        </div>
      )}
    </Fragment>)
}

Slider.propTypes = {
  getsliders: PropTypes.func.isRequired,
  sliderdelete:PropTypes.func.isRequired,
	slider: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	slider: state.slider,
});

export default connect(mapStateToProps, { getsliders,sliderdelete})(Slider);
