import axios from 'axios';
import { GET_MODALS, MODAL_ERROR,GET_MODAL, MODAL_UPDATED, MODAL_DELETED } from './types';

// Toast
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure();


// get all the published modals
export const getPmodals = () => async dispatch => {
	try {
		const res = await axios.get('/api/modal');

		dispatch({
			type: GET_MODALS,
			payload: res.data,
		});
	} catch (err) {
		dispatch({
			type: MODAL_ERROR,
			payload: { msg: err.response.statusText, status: err.response.status },
		});
	}
};

//create new modal
export const modaladd = (fd, history) => async dispatch => {
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	};

	try {
		await axios.post('/api/modal', fd, config);

		toast.success('Modal Successfully Added');
		history.push('/admin/modals');
	} catch (err) {
		const errors = err.response.data.errors;

		if (errors) {
			errors.forEach(error => toast.error(error.msg));
		}

		dispatch({
			type: MODAL_ERROR,
		});
	}
};


// get all the modals
export const getmodals = () => async dispatch => {
	try {
		const res = await axios.get('/api/modal/all');

		dispatch({
			type: GET_MODALS,
			payload: res.data,
		});
	} catch (err) {
		dispatch({
			type: MODAL_ERROR,
			payload: { msg: err.response.statusText, status: err.response.status },
		});
	}
};


//delete modal
export const modaldelete = modalId => async dispatch => {
	if (window.confirm('Are you sure? You want to delete ?')) {
		try {
			await axios.delete(`../api/modal/${modalId}`);

			dispatch({
				type: MODAL_DELETED,
				payload: modalId,
			});

			toast.success('Modal Successfully Removed');
		} catch (err) {
			const errors = err.response.data.errors;

			if (errors) {
				errors.forEach(error => toast.error(error.msg));
			}

			dispatch({
				type: MODAL_ERROR,
			});
		}
	}
};

// get modal by id
export const getmodalbyid = modalid => async dispatch => {
	try {
		const res = await axios.get(`/api/modal/${modalid}`);
		dispatch({
			type: GET_MODAL,
			payload: res.data,
		});
	} catch (err) {
		dispatch({
			type: MODAL_ERROR,
			payload: { msg: err.response.statusText, status: err.response.status },
		});
	}
};

//update modal by id
export const modalupdate = (modalid, fd, history) => async dispatch => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		};

		const res = await axios.put(`/api/modal/${modalid}`, fd, config);

		dispatch({
			type: MODAL_UPDATED,
			payload: res.data,
		});
		history.push('/admin/modals');
		toast.success('Modals Successfully Updated');
	} catch (err) {
		const errors = err.response.data.errors;

		if (errors) {
			errors.forEach(error => toast.error(error.msg));
		}

		dispatch({
			type: MODAL_ERROR,
			payload: { msg: err.response.statusText, status: err.response.status },
		});
	}
};