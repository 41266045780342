import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { internetplanadd } from '../../../actions/internetplan';
import { Link, withRouter } from 'react-router-dom';

const InternetPlanAdd = ({ internetplanadd, history }) => {
	const [formData, setFormData] = useState({
		speed: '',		
		publish: '',
		sme: ''
	});

    const { speed,sme,publish} = formData;

	const onChange = e =>
		setFormData({ ...formData, [e.target.name]: e.target.value });

	const onCheckChange = e => {
		setFormData({ ...formData, publish: !publish });
    };
    
    const onCheckSmeChange = e => {
		setFormData({ ...formData, sme: !sme });
	};

	const onSubmit = e => {
		e.preventDefault();
		internetplanadd(formData, history);
	};

	return (
		<Fragment>
             <div className="col-sm-9 col-sm-offset-3 col-lg-10 col-lg-offset-2">
			
			<div className='content-wrapper'>
				{/* Content Header (Page header) */}
				<section className='content-header'>
					<div className='container-fluid'>
						<div className='row mb-2'>
							<div className='col-sm-6'>
								<h1>Add Internet Plan</h1>
							</div>
						</div>
					</div>
					{/* /.container-fluid */}
				</section>
				{/* Main content */}
				<section className='content'>
					<div className='container-fluid'>
						<div className='row'>
							{/* left column */}
							<div className='col-md-12'>
								{/* jquery validation */}
								<div >
									<div className='card-header'>
										<h3 className='card-title'>Fields with * are required</h3>
									</div>
									{/* /.card-header */}
									{/* form start */}
									<form onSubmit={e => onSubmit(e)}>
										<div className='card-body'>
											<div className='row'>
												<div className='col-sm-6'>
													{/* text input */}
													<div className='form-group'>
														<label htmlFor='speed'>
															Speed *
														</label>
														<input
															type='text'
															name='speed'
															value={speed}
															onChange={e => onChange(e)}
                                                            className='form-control'
                                                            id='speed'														
															placeholder='00 Mbps'
															
														/>
													</div>
												</div>
												<div className='col-sm-6'>													
													<div className='form-group'>
													<label htmlFor='publish'>Publish: </label>{' '}
													<input
													type='checkbox'
													name='publish'
													checked={publish}
													onChange={e => onCheckChange(e)}
													/>
													</div>
                                                   
                                                    <div className='form-group'>
													<label htmlFor='sme'>SME: </label>{' '}
													<input
													type='checkbox'
													name='sme'
													checked={sme}
													onChange={e => onCheckSmeChange(e)}
													/>
													</div>
												</div>	                     
												</div>                                             
										</div>
										<div className='card-footer'>
											<button type='submit' className='btn btn-primary'>
												Submit
											</button>
											{'  '}
											<Link
												className=' btn btn-danger'
												to='../../../admin/internet_plans'
												role='button'
											>
												Cancel
											</Link>
										</div>
									</form>
								</div>
								{/* /.card */}
							</div>
							{/*/.col (left) */}
							{/* right column */}
							<div className='col-md-6'></div>
							{/*/.col (right) */}
						</div>
						{/* /.row */}
					</div>
					{/* /.container-fluid */}
				</section>
				{/* /.content */}
			</div>
            </div>
		</Fragment>
	);
};

InternetPlanAdd.propTypes = {
	internetplanadd: PropTypes.func.isRequired,
};

export default connect(null, { internetplanadd })(withRouter(InternetPlanAdd));
