import React, { Fragment, useState, useEffect } from 'react';
import Spinner from '../../layout/Spinner';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
	getbookings,
	bookingdelete,
	bookingupdate,
} from '../../../actions/booking';

import ReactPaginate from 'react-paginate';

const Booking = ({
	getbookings,
	bookingdelete,
	bookingupdate,
	booking: { bookings, loading },
}) => {
	useEffect(() => {
		getbookings();
	}, [getbookings]);

	var [sn] = useState(1);

	const [pageNumber, setPageNumber] = useState(0);
	const contentPerPage = 20;
	const pagesVisited = pageNumber * contentPerPage;
	const changePage = ({ selected }) => {
		setPageNumber(selected);
	};

	return (
		<Fragment>
			{loading ? (
				<Spinner />
			) : (
				<div className='col-sm-9 col-sm-offset-3 col-lg-10 col-lg-offset-2'>
					<div>
						<div className='row'>
							<div className='col-lg-12'>
								<h1 className='page-header'>Bookings</h1>
							</div>
						</div>
						{/*/.row*/}
						<div className='panel panel-container'>
							<container className='container-fluid'>
								<div className='table-responsive'>
									<table className='table table-striped table-hover'>
										<thead>
											<tr>
												<th>S.N</th>
												<th>Date</th>
												<th>Package</th>
												<th>Duration</th>
												<th>Price</th>
												<th>Name</th>
												<th>Mobile</th>
												<th>Address</th>
												<th>Email</th>
												<th>Message</th>
												<th>Status</th>
												<th>Action</th>
											</tr>
										</thead>
										<tbody>
											{bookings.length > 0
												? bookings
														.slice(pagesVisited, pagesVisited + contentPerPage)
														.map((booking) => (
															<tr key={booking._id}>
																<td className='text-left'>{sn++}</td>
																<td className='text-left'>
																	{moment(booking.createdAt).format(
																		'YYYY-MM-DD hh:mm'
																	)}
																</td>
																<td className='text-left'>{booking.plan}</td>
																<td className='text-left'>
																	{booking.duration}
																</td>
																<td className='text-left'>{booking.price}</td>
																<td className='text-left'>{booking.name}</td>
																<td className='text-left'>{booking.mobile}</td>
																<td className='text-left'>{booking.address}</td>
																<td className='text-left'>{booking.email}</td>
																<td className='text-left'>{booking.message}</td>
																<td className='text-left'>{booking.status}</td>

																<td>
																	{' '}
																	<Link
																		onClick={() =>
																			bookingupdate(booking._id, {
																				status: 'confirmed',
																			})
																		}
																	>
																		{' '}
																		Confirmed
																	</Link>{' '}
																	|
																	<Link
																		onClick={() =>
																			bookingupdate(booking._id, {
																				status: 'cancelled',
																			})
																		}
																	>
																		{' '}
																		Cancelled
																	</Link>{' '}
																	|
																	<Link
																		onClick={() => bookingdelete(booking._id)}
																	>
																		{' '}
																		Delete
																	</Link>
																</td>
															</tr>
														))
												: 'Booking not found'}
										</tbody>
									</table>
								</div>
							</container>
						</div>
						<ReactPaginate
							previousLabel={'Previous'}
							nextLabel={'Next'}
							pageCount={Math.ceil(bookings.length / contentPerPage)}
							onPageChange={changePage}
							containerClassName={'paginationButtons'}
							previousLinkClassName={'previousButtons'}
							nextLinkClassName={'nextButtons'}
							disabledClassName={'paginationDisable'}
							activeClassName={'paginationActive'}
						/>
					</div>
				</div>
			)}
		</Fragment>
	);
};

Booking.propTypes = {
	getbookings: PropTypes.func.isRequired,
	bookingdelete: PropTypes.func.isRequired,
	bookingupdate: PropTypes.func.isRequired,
	booking: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	booking: state.booking,
});

export default connect(mapStateToProps, {
	getbookings,
	bookingupdate,
	bookingdelete,
})(Booking);
