import React from 'react'
import ContactForm from '../forms/ContactForm'

const Contact = () => {
    return (
       <div>
  <div className="contact">
    <div className="jumbotron hero-technology">
      <h1 className="hero-title">Airson Internet Pvt Ltd</h1>
      <p className="hero-subtitle">We are always available 24 x 7. To contact us please contact us via your most comfortable medium. For any feedback / complaint please use the form below.</p>
      {/* <button className="btn btn-default btn-cstm" type="submit" id="feedback_contact" data-toggle="modal" data-target="#modal1">Feedback / Complaint</button> */}
    </div>
    <div className="container-fluid">
      <div className="row contact_info">
        <div className="col-md-6">
          <div className="contact-details">
            <div className="contact_details_head">
              <h1 className="title_section">Our Contact Details</h1>
              <h4 className="subtitle_section">Get in Touch</h4></div>
            <div className="line-border" />
            <p className="lead text-left cd_p"><strong><span style={{textDecoration: 'underline'}}>Technical Support</span></strong></p>
            <p className="text-left">For support or any questions</p>
            <p className="text-left">Email us at <span style={{color: '#fa5c0e'}}><strong>support@airson.com.np </strong></span></p>
            <p className="lead text-left cd_p"><strong><span style={{textDecoration: 'underline'}}>Head Office</span></strong></p>
            <p className="text-left">Chauthe, Pokhara-14 (Infront of DR Petrol Pump)</p>
            <p className="text-left"> Kaski, Gandaki Province</p>
            <p className="text-left">Toll-Free : <i className="glyphicon glyphicon-earphone" /> 1660-61-52095</p>
            <p className="text-left">Tel : <i className="glyphicon glyphicon-earphone" /> 061-501230</p>
            <p className="text-left">Mobile : <i className="glyphicon glyphicon-phone" /> +977-9802859703</p>
            <p className="lead text-left cd_p"><strong><span style={{textDecoration: 'underline'}}>Find Us On :</span></strong></p>
            <ul className="list-inline bottom-social text-left">
              <li><a href="https://www.facebook.com/airson.com.np" rel="noopener noreferrer" target="_blank" className="social_icon_li socio_list"><i className="fa fa-facebook" /></a></li>
              <li><a href="https://www.instagram.com/airson.com.np" rel="noopener noreferrer" target="_blank" className="social_icon_li socio_list"><i className="fa fa-instagram" /></a></li>
            </ul>
          </div>
        </div>
        <div className="col-md-6 contact_us_col_right">
          <ContactForm />
        </div>
      </div>
    </div>
  </div>
  <div className="container-fluid">
    <hr style={{height: 10}} />
    <div className="row">
      <div className="col-md-12">
        <div className="location_details">
          <h1 className="title_section">Our Locations</h1>
          <h4 className="subtitle_section">Visit Us If Nearby !</h4>
          <iframe title='google-map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3516.4537141449773!2d84.01999131440644!3d28.193524710566194!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjjCsDExJzM2LjciTiA4NMKwMDEnMTkuOSJF!5e0!3m2!1sen!2snp!4v1602332728144!5m2!1sen!2snp" width="100%" height={350} frameBorder={0} style={{border: 0}} allowFullScreen aria-hidden="false" tabIndex={0} />
        </div>
      </div>
    </div>
  </div>
</div>

    )
}

export default Contact