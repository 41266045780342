import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

//Adding Messenger Bot
import MessengerCustomerChat from 'react-messenger-customer-chat';

import { Landing } from './Components/layout/Landing';
import { Navbar } from './Components/layout/Navbar';
import { Topbar } from './Components/layout/Topbar';
import { Footer } from './Components/layout/Footer';
import { Footerbar } from './Components/layout/Footerbar';

import Routes from './Components/routing/Routes'
import AdminRoutes from './Components/routing/AdminRoutes'
import PrivateRoute from './Components/routing/PrivateRoute'

//Redux
import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';

import './App.css';

if(localStorage.token){
	setAuthToken(localStorage.token);
}

const App = () => { 
	useEffect(() => {
		store.dispatch(loadUser()); 
	}, []);
	
	return (
		
	<Provider store={store}>
		<div>
    <MessengerCustomerChat
      pageId="635783930381384"
      appId="484112246256940"
    />
  </div>
		<Router>
			<Switch>
			<PrivateRoute path="/admin/*" component={AdminRoutes} />		
			<Fragment>			
				<Topbar />
				<Navbar />
				<Switch>
				<Route exact path='/' component={Landing} />
				<Route component={Routes} />
				</Switch>	
				<Footer />			
				<Footerbar />
			</Fragment>
			</Switch>			
		</Router>
	</Provider>
) };

export default App;
