import React, {Fragment,useState, useEffect} from 'react'
import Spinner from '../../layout/Spinner';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { getinternetplanbyid,packagedelete } from '../../../actions/internetplan';

const InternetPlanDetail = ({ getinternetplanbyid,packagedelete,match, internetplan: { internetplan, loading } }) => {
  useEffect(() => {
		getinternetplanbyid(match.params.id);
	}, [getinternetplanbyid, match.params.id]);

	var [sn] = useState(1);
    return (
      <Fragment>
      {loading ? (
				<Spinner />
			) : (
        <div className="col-sm-9 col-sm-offset-3 col-lg-10 col-lg-offset-2">
          <div>
  <div className="row">
    <div className="col-lg-12">
      <h1 className="page-header">{internetplan.speed}</h1>
    </div>
  </div>{/*/.row*/}
  <div className="panel panel-container">
    <container className="container-fluid">
    <Link
      className='btn btn-default btn-lg'
      to={`../../../admin/internet_plan/${match.params.id}/add`}
      role='button'
    >
      Add New Package
    </Link>
      <div className="table-responsive">
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>S.N</th>
              <th>Duration</th>
              <th>Internet Cost</th>  
              <th>IPTV Cost</th>
              <th>Total Cost</th>              
              <th>Updated Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
          {internetplan.list.length > 0 ? internetplan.list.map(l => (<tr key={l._id}>																
              <td className='text-left'>{sn++}</td>
              <td className='text-left'>{l.duration}</td>															
              <td className='text-left'>{l.internetcost + l.onucharge + l.wirecharge + l.installationcharge +l.securitydeposit}</td>														
              <td className='text-left'>{l.iptvcost + l.settopboxcharge}</td>														
              <td className='text-left'>{l.iptvcost + l.settopboxcharge + l.internetcost + l.onucharge + l.wirecharge + l.installationcharge +l.securitydeposit}</td>														
              <td className='text-left'>{moment(l.updatedAt).format(
                      'YYYY-MM-DD')}</td>              
              <td>
              <a href={`/admin/internet_plan/${match.params.id}/${l._id}`}> Edit </a>  | 
              <Link onClick={() => packagedelete(match.params.id,l._id)}>
                {' '}	Delete
                  </Link>
                  </td>
            </tr>       )) : 'List Not Found'}
                  
          </tbody>
        </table>
      </div>
    </container>
  </div>
</div>

        </div>
        )}
        </Fragment>
    )
}

InternetPlanDetail.propTypes = {
  getinternetplanbyid: PropTypes.func.isRequired,
  packagedelete :PropTypes.func.isRequired,
	internetplan: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	internetplan: state.internetplan,
});

export default connect(mapStateToProps, { getinternetplanbyid, packagedelete})(InternetPlanDetail);

