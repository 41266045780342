import React, {Fragment,useState, useEffect} from 'react'
import Spinner from '../../layout/Spinner';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { getinternetplans,internetplandelete } from '../../../actions/internetplan';

const InternetPlan = ({ getinternetplans,internetplandelete, internetplan: { internetplans, loading } }) => {
  useEffect(() => {
		getinternetplans();
	}, [getinternetplans]);

	var [sn] = useState(1);


    return (
      <Fragment>
      {loading ? (
				<Spinner />
			) : (
        <div className="col-sm-9 col-sm-offset-3 col-lg-10 col-lg-offset-2">
          <div>
  <div className="row">
    <div className="col-lg-12">
      <h1 className="page-header">Internet Plans</h1>
    </div>
  </div>{/*/.row*/}
  <div className="panel panel-container">
    <container className="container-fluid">
    <Link
										className='btn btn-default btn-lg'
										to='../../../admin/internet_plan/add'
										role='button'
									>
										Add New Internet Plan
									</Link>
      <div className="table-responsive">
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>S.N</th>
              <th>Type</th>
              <th>Speed</th>              
              <th>Updated Date</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
          {internetplans.length >= 0
													? internetplans.map(internetplan => (
															<tr key={internetplan._id}>																
																<td className='text-left'>{sn++}</td>
																<td className='text-left'>{internetplan.sme ? 'SME User': 'Home User'}</td>															
																<td className='text-left'>{internetplan.speed}</td>																
																<td className='text-left'>{moment(internetplan.updatedAt).format(
																				'YYYY-MM-DD')}</td>
																<td className='text-left'>{internetplan.publish ? 'Published' : 'Not Published'}</td>
                                
              <td><a href={`/admin/internet_plan/${internetplan._id}/detail`}> View </a>  | 
                 <a href={`/admin/internet_plan/${internetplan._id}/edit`}> Edit </a>  | 
               <Link onClick={() => internetplandelete(internetplan._id)}>
																	{' '}	Delete
																		</Link>
                                    </td>
															</tr>
													  ))
													: 'Internet Plan not found'}
            
          </tbody>
        </table>
      </div>
    </container>
  </div>
</div>

        </div>
        )}
        </Fragment>
    )
}

InternetPlan.propTypes = {
  getinternetplans: PropTypes.func.isRequired,
  internetplandelete:PropTypes.func.isRequired,
	internetplan: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	internetplan: state.internetplan,
});

export default connect(mapStateToProps, { getinternetplans,internetplandelete})(InternetPlan);

