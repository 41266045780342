import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom'

const SliderItem = ({
	slider: { _id, title, description, image, buttontitle, buttonlink },
}) => {
	return (
		<div className='item'>
			<img
				className='img-responsive'
				src={image}
				alt='Slider'
				width="100%" height="auto"											
			/>
			<div className="carousel-caption carousel-content">
  <h1 className="text-left">{title} </h1>
  <div className="row">
    <div className="col-md-6">
	<p className="text-left">{description}</p>
    </div>
  </div>
	{buttontitle ? (<button className="btn btn-default btn-sm btn-cstm add_btn" type="button"><Link to={buttonlink}>{buttontitle}</Link></button>) : ''}
  
</div>
		</div>
	);
};

SliderItem.propTypes = {
	slider: PropTypes.object.isRequired,
};

export default SliderItem;
