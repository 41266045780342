import {
	GET_SLIDERS,
	SLIDER_ERROR,
	GET_SLIDER,
	SLIDER_DELETED,
	SLIDER_UPDATED,
} from '../actions/types';

const initialState = {
	slider: null,
	sliders: [],
	loading: true,
	error: {},
};

function slider(state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case GET_SLIDERS:
			return {
				...state,
				sliders: payload,
				loading: false,
			};
		case GET_SLIDER:
		case SLIDER_UPDATED:
			return {
				...state,
				slider: payload,
				loading: false,
			};
		case SLIDER_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
			};
		case SLIDER_DELETED:
			return {
				...state,
				sliders: state.sliders.filter((slider) => slider._id !== payload),
				loading: false,
			};
		default:
			return state;
	}
}

export default slider;
