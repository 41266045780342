import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { sendEmail } from '../../actions/form';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';

const DedicatedForm = ({ sendEmail }) => {
	const [formData, setFormData] = useState({
		name: '',
		mobile: '',
		email: '',
		message: '',
		organization: '',
		address: '',
	});
	const reCaptcha = useRef();

	const [isVerified, setIsVerified] = useState(false);
	function gonChange(value) {
		setIsVerified(true);
	}

	const { name, mobile, email, message, organization, address } = formData;

	const onChange = (e) =>
		setFormData({ ...formData, [e.target.name]: e.target.value });

	const onSubmit = (e) => {
		e.preventDefault();
		if (!isVerified) {
			toast.error('Invalid Captcha');
			return;
		}
		sendEmail(formData);
		setIsVerified(false);
		reCaptcha.current.reset();
		setFormData({
			name: '',
			mobile: '',
			email: '',
			message: '',
			organization: '',
			address: '',
		});
	};

	return (
		<div className='container dedicated_f'>
			<div className='col-md-6'>
				<p className='lead text-left'>Please fill this form</p>
				<form onSubmit={(e) => onSubmit(e)}>
					<input
						className='form-control'
						type='text'
						required
						name='organization'
						value={organization}
						onChange={(e) => onChange(e)}
						placeholder='Organization Name'
					/>
					<input
						className='form-control'
						type='text'
						required
						name='address'
						value={address}
						onChange={(e) => onChange(e)}
						placeholder='Address'
					/>
					<input
						className='form-control'
						type='number'
						required
						placeholder='Contact No'
						name='mobile'
						value={mobile}
						onChange={(e) => onChange(e)}
						minLength={10}
						inputMode='tel'
					/>
					<input
						className='form-control'
						type='text'
						required
						name='name'
						value={name}
						onChange={(e) => onChange(e)}
						placeholder='Your Name'
					/>
					<input
						className='form-control'
						type='email'
						placeholder='Your E-mail'
						name='email'
						value={email}
						onChange={(e) => onChange(e)}
						inputMode='email'
					/>
					<textarea
						className='form-control'
						wrap='hard'
						name='message'
						value={message}
						onChange={(e) => onChange(e)}
						placeholder='Message | Details'
					/>
					<ReCAPTCHA
						ref={reCaptcha}
						className='captcha_control'
						sitekey='6LdT9D4bAAAAAP1hvSJarS3nDaxWwJY5fx7SgYtR'
						onChange={gonChange}
					/>
					<button className='btn btn-default btn-cstm' type='submit'>
						Book Now
					</button>
				</form>
			</div>
			<div className='col-md-6'>
				<img
					className='img-rounded img-responsive'
					alt='dedicated'
					src='assets/img/undraw_Booking_re_gw4j.png'
					width={550}
					height={400}
				/>
			</div>
		</div>
	);
};

DedicatedForm.propTypes = {
	sendEmail: PropTypes.func.isRequired,
};

export default connect(null, { sendEmail })(DedicatedForm);
