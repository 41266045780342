import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TestimonialItem from './TestimonialItem';
import { Line } from './Line';

import { getPtestimonials } from '../../actions/testimonial';

const Testimonials = ({
	getPtestimonials,
	testimonial: { testimonials, loading },
}) => {
	useEffect(() => {
		getPtestimonials();
	}, [getPtestimonials]);

	return loading ? (
		''
	) : (
		<Fragment>
			{testimonials.length > 0 ? (
				<>
					{' '}
					<Line />{' '}
					<div className='testimonials'>
						<div>
							<h1 className='title_section'>What Our Clients Say</h1>
							<h4 className='subtitle_section'>Testimonials </h4>
						</div>
						<div
							className='carousel slide testimonials'
							data-ride='carousel'
							id='carousel-2'
						>
							<div className='carousel-inner' role='listbox'>
								{testimonials.length > 0
									? testimonials.slice(0, 1).map((testimonial) => (
											<div className='item active testimonials_slide'>
												{testimonial.image ? (
													<img
														className='img-circle testimonial_img'
														alt='Slider'
														src={`${testimonial.image}`}
														width='100'
														height='100'
													/>
												) : (
													<img
														className='img-circle testimonial_img'
														alt='Slider'
														src='assets/img/logo.png'
														width='100'
														height='100'
													/>
												)}
												<div className='carousel-caption testimonials-content'>
													<h4 className='text-uppercase text-center'>
														{testimonial.fullname}
													</h4>
													<p>( {testimonial.profession} )</p>
													<p className='text-center'>
														{testimonial.information}
													</p>
												</div>
											</div>
									  ))
									: ''}
								{testimonials.length > 1
									? testimonials
											.slice(1, testimonials.length)
											.map((testimonial) => (
												<TestimonialItem
													key={testimonial._id}
													testimonial={testimonial}
												/>
											))
									: ''}
							</div>
							<div>
								<a
									className='left carousel-control'
									href='#carousel-2'
									role='button'
									data-slide='prev'
								>
									<i className='glyphicon glyphicon-chevron-left'></i>
									<span className='sr-only'>Previous</span>
								</a>
								<a
									className='right carousel-control'
									href='#carousel-2'
									role='button'
									data-slide='next'
								>
									<i className='glyphicon glyphicon-chevron-right'></i>
									<span className='sr-only'>Next</span>
								</a>
							</div>
						</div>
					</div>
					)
					<Line />
				</>
			) : (
				''
			)}
		</Fragment>
	);
};

Testimonials.propTypes = {
	getPtestimonials: PropTypes.func.isRequired,
	testimonial: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	testimonial: state.testimonial,
});

export default connect(mapStateToProps, { getPtestimonials })(Testimonials);
