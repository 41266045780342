import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { referraladd } from '../../actions/referral';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';

const Referral = ({ referraladd }) => {
	const [formData, setFormData] = useState({
		auname: '',
		aumobile: '',
		auemail: '',
		auusername: '',
		rpaddress: '',
		rpmobile: '',
		rpphone: '',
		rpname: '',
		rpemail: '',
		message: '',
	});
	const reCaptcha = useRef();
	const [isVerified, setIsVerified] = useState(false);
	function gonChange(value) {
		setIsVerified(true);
	}

	const {
		auname,
		aumobile,
		auemail,
		auusername,
		rpaddress,
		rpmobile,
		rpname,
		rpemail,
		message,
	} = formData;

	const onChange = (e) =>
		setFormData({ ...formData, [e.target.name]: e.target.value });

	const onSubmit = (e) => {
		e.preventDefault();
		if (!isVerified) {
			toast.error('Invalid Captcha');
			return;
		}
		referraladd(formData);
		setIsVerified(false);
		reCaptcha.current.reset();
		setFormData({
			auname: '',
			aumobile: '',
			auemail: '',
			auusername: '',
			rpaddress: '',
			rpmobile: '',
			rpname: '',
			rpemail: '',
			message: '',
		});
	};
	return (
		<div>
			<div className='container'>
				<div>
					<h1 className='title_section'>Refer a Friend !</h1>
					<h4 className='subtitle_section'>
						Let's know your friends and family about Airson.
					</h4>
				</div>
			</div>
			<div className='container referral_page'>
				<h3>Your Details:</h3>
				<div className='referral_form'>
					<form onSubmit={(e) => onSubmit(e)}>
						<div className='row'>
							<div className='col-md-6'>
								<input
									className='form-control bform'
									type='text'
									required
									name='auname'
									placeholder='Your Registered Full Name'
									onChange={(e) => onChange(e)}
									value={auname}
								/>
								<input
									className='form-control bform'
									type='email'
									name='auemail'
									placeholder='Your Registered Email Address'
									onChange={(e) => onChange(e)}
									value={auemail}
								/>
							</div>
							<div className='col-md-6'>
								<input
									className='form-control bform'
									type='number'
									minLength={10}
									required
									name='aumobile'
									placeholder='Your Registered Mobile Number'
									onChange={(e) => onChange(e)}
									value={aumobile}
								/>
								<input
									className='form-control bform'
									type='text'
									required
									name='auusername'
									placeholder='Your Username'
									onChange={(e) => onChange(e)}
									value={auusername}
								/>
							</div>
						</div>

						<h3>Referring Person Details:</h3>

						<div className='row'>
							<div className='col-md-6'>
								<input
									className='form-control bform'
									type='text'
									required
									name='rpname'
									placeholder='Full Name'
									onChange={(e) => onChange(e)}
									value={rpname}
								/>
								<input
									className='form-control bform'
									type='email'
									name='rpemail'
									placeholder='Email Address'
									onChange={(e) => onChange(e)}
									value={rpemail}
								/>
							</div>
							<div className='col-md-6'>
								<input
									className='form-control bform'
									type='number'
									minLength={10}
									required
									name='rpmobile'
									placeholder='Mobile Number'
									onChange={(e) => onChange(e)}
									value={rpmobile}
								/>
								<input
									className='form-control bform'
									type='text'
									required
									name='rpaddress'
									placeholder='Address'
									onChange={(e) => onChange(e)}
									value={rpaddress}
								/>
							</div>
						</div>
						<textarea
							className='form-control bform'
							rows='3'
							name='message'
							value={message}
							onChange={(e) => onChange(e)}
							placeholder='Additional Details (Optional)'
						/>
						<ReCAPTCHA
							ref={reCaptcha}
							sitekey='6LdT9D4bAAAAAP1hvSJarS3nDaxWwJY5fx7SgYtR'
							onChange={gonChange}
						/>
						<br />
						<button
							className='btn btn-default btn-block btn-cstm'
							type='submit'
						>
							Submit
						</button>
					</form>
					<br />
				</div>
			</div>
		</div>
	);
};

Referral.propTypes = {
	referraladd: PropTypes.func.isRequired,
};

export default connect(null, { referraladd })(Referral);
