import React from 'react';

export const Topbar = () => {
	return (
    
		<div className="container-fluid social-icon-bar">
  <div className="row top_social_row">
    <div className="col-md-6 col-sm-6 col-xs-12">
      <ul className="list-inline top-social">
        <li><a href="https://www.facebook.com/airson.com.np" rel="noopener noreferrer" target="_blank" className="social_icon_li"><i className="fa fa-facebook" /></a></li>
        <li><a href="https://www.instagram.com/airson.com.np" rel="noopener noreferrer" target="_blank" className="social_icon_li"><i className="fa fa-instagram" /></a></li>
      </ul>
    </div>
    <div className="col-md-6 col-sm-6 col-xs-12">
      <ul className="list-inline top-social text-right">
      <li>
							<a
								href='https://support.airson.com.np'
								rel='noopener noreferrer'
								target='_blank'
								class='btn btn-success btn-xs'
								role='button'
							>
								Customer Login
							</a>
						</li>
        <li><i className="fa fa-envelope" /> contact@airson.com.np</li>
        <li><i className="fa fa-phone" /> 1660-61-52095</li>       
      </ul>
    </div>
  </div>
</div>

	);
};
