import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getsliderbyid, sliderupdate } from '../../../actions/slider';
import Spinner from '../../layout/Spinner';
import { withRouter } from 'react-router-dom';
import {Link } from 'react-router-dom'

const SliderEdit = ({
	getsliderbyid,
	sliderupdate,
	slider: { slider, loading },
	match,
	history,
}) => {
	const [formData, setFormData] = useState({
        title: '',		
		description: '',
		buttontitle: '',	
        buttonlink: '',
        publish:'',
        image:''
	});

	useEffect(() => {
		getsliderbyid(match.params.id);

		setFormData({
			title: loading || !slider.title ? '' : slider.title,
			description: loading || !slider.description ? '' : slider.description,
			buttonlink: loading || !slider.buttonlink ? '' : slider.buttonlink,
			buttontitle: loading || !slider.buttontitle ? '' : slider.buttontitle,
			publish: loading || !slider.publish ? '' : slider.publish,
			image: loading || !slider.image ? '' : slider.image,
		});
	}, [getsliderbyid, loading]);

	const { title,description, buttonlink,buttontitle,image, publish } = formData;


	const onChange = e =>
		setFormData({ ...formData, [e.target.name]: e.target.value });

	const onCheckChange = e => {
		setFormData({ ...formData, publish: !publish });
	};

	const onSubmit = e => {
		e.preventDefault();
		sliderupdate(match.params.id, formData, history);
	};

	return loading ? (
		<Spinner />
	) : (
		<Fragment>
			
            <div className="col-sm-9 col-sm-offset-3 col-lg-10 col-lg-offset-2">
			
			<div className='content-wrapper'>
				{/* Content Header (Page header) */}
				<section className='content-header'>
					<div className='container-fluid'>
						<div className='row mb-2'>
							<div className='col-sm-6'>
								<h1>Edit Slider</h1>
							</div>
						</div>
					</div>
					{/* /.container-fluid */}
				</section>
				{/* Main content */}
				<section className='content'>
					<div className='container-fluid'>
						<div className='row'>
							{/* left column */}
							<div className='col-md-12'>
								{/* jquery validation */}
								<div >
									<div className='card-header'>
										<h3 className='card-title'>Fields with * are required</h3>
									</div>
									{/* /.card-header */}
									{/* form start */}
									<form onSubmit={e => onSubmit(e)}>
										<div className='card-body'>
											<div className='row'>
												<div className='col-sm-6'>
													{/* text input */}
													<div className='form-group'>
														<label htmlFor='title'>
															Title *
														</label>
														<input
															type='text'
															name='title'
															value={title}
															onChange={e => onChange(e)}
                                                            className='form-control'
                                                            id='title'														
															placeholder='Title'
															
														/>
													</div>
												</div>
												
											</div>

                                            <div className='row'>
												<div className='col-sm-6'>
													{/* text input */}
													<div className='form-group'>
														<label htmlFor='buttontitle'>Button Title</label>
														<input
															type='text'
															name='buttontitle'
															value={buttontitle}
															onChange={e => onChange(e)}
															className='form-control'
															id='buttontitle'
															placeholder='Enter Button Title'
														/>
													</div>
												</div>
												
											</div>

											<div className='row'>
												<div className='col-sm-6'>
													{/* text input */}
													<div className='form-group'>
														<label htmlFor='buttonlink'>Button Link</label>
														<input
															type='text'
															name='buttonlink'
															value={buttonlink}
															onChange={e => onChange(e)}
															className='form-control'
															id='buttonlink'
															placeholder='Enter Button Link'
														/>
													</div>
												</div>
												
											</div>
                                            <div className='row'>
												<div className='col-sm-6'>
													{/* text input */}
													<div className='form-group'>
														<label htmlFor='description'>
															Description
														</label>
                                                        <textarea name='description'
															value={description}
															onChange={e => onChange(e)}
															className='form-control'
															id='description'
															placeholder='Enter Description' rows="3"></textarea>
														
													</div>
												</div>
												
												
											</div>
											<div className='row'>
												<div className='col-sm-6'>
													{/* text input */}
													<div className='form-group'>
													<label htmlFor='publish'>Publish: </label>{' '}
													<input
													type='checkbox'
													name='publish'
													checked={publish}
													onChange={e => onCheckChange(e)}
													/>
													</div>
												</div>									
											</div>
										</div>
										<div className='card-footer'>
											<button type='submit' className='btn btn-primary'>
												Submit
											</button>
											{'  '}
											<Link
												className=' btn btn-danger'
												to='../../../admin/sliders'
												role='button'
											>
												Cancel
											</Link>
										</div>
									</form>
								</div>
								{/* /.card */}
							</div>
							{/*/.col (left) */}
							{/* right column */}
							<div className='col-md-6'></div>
							{/*/.col (right) */}
						</div>
						{/* /.row */}
					</div>
					{/* /.container-fluid */}
				</section>
				{/* /.content */}
			</div>
            </div>
		</Fragment>
	);
};

SliderEdit.propTypes = {
	getsliderbyid: PropTypes.func.isRequired,
	sliderupdate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	slider: state.slider,
});

export default connect(mapStateToProps, { getsliderbyid, sliderupdate })(
	withRouter(SliderEdit)
);
