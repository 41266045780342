import {
	GET_MODALS,
	MODAL_ERROR,
	GET_MODAL,
	MODAL_DELETED,
	MODAL_UPDATED,
} from '../actions/types';

const initialState = {
	modal: null,
	modals: [],
	loading: true,
	error: {},
};

function modal(state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case GET_MODALS:
			return {
				...state,
				modals: payload,
				loading: false,
			};
		case GET_MODAL:
		case MODAL_UPDATED:
			return {
				...state,
				modal: payload,
				loading: false,
			};
		case MODAL_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
			};
		case MODAL_DELETED:
			return {
				...state,
				modals: state.modals.filter((modal) => modal._id !== payload),
				loading: false,
			};
		default:
			return state;
	}
}

export default modal;
