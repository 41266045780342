import React, { Fragment } from 'react'
import {Route, Switch} from 'react-router-dom';
import Login  from '../auth/Login';
import ForgetPassword  from '../auth/ForgetPassword';
import ResetPassword  from '../auth/ResetPassword';
import NotFound from '../layout/NotFound';
import Support from '../support/Support';
import About from '../about/About';
import Contact from '../contact/Contact';
import Referral from '../referral/Referral';
import Packages from '../packages/Packages';
import Booking from '../booking/Booking';
import Faq from '../faq/Faq';
import { Line } from '../layout/Line';


const Routes = () => {
    return (
		<Fragment>
		<Line />			
			<Switch>
				<Route exact path='/admin-login' component={Login} />
				<Route exact path='/forget_password' component={ForgetPassword} />
				<Route exact path='/resetpassword/:token' component={ResetPassword} />
				<Route exact path='/support' component={Support} />
				<Route exact path='/about_us' component={About} />
				<Route exact path='/contact_us' component={Contact} />
				<Route exact path='/referral' component={Referral} />
				<Route exact path='/packages' component={Packages} />
				<Route exact path='/booking' component={Booking} />
				<Route exact path='/faq' component={Faq} />
				<Route component={NotFound} />
			</Switch>
		
		</Fragment>
    )
}

export default Routes
