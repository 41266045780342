import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { forgetpassword } from '../../actions/user';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';

const ForgetPassword = ({ forgetpassword, isAuthenticated }) => {
	const [formData, setFormData] = useState({
		email: '',
	});

	const { email } = formData;

	const reCaptcha = useRef();
	const [isVerified, setIsVerified] = useState(false);
	function gonChange(value) {
		setIsVerified(true);
	}

	const onChange = (e) =>
		setFormData({ ...formData, [e.target.name]: e.target.value });

	const onSubmit = async (e) => {
		e.preventDefault();
		if (!isVerified) {
			toast.error('Invalid Captcha');
			return;
		}
		forgetpassword(email);
		setIsVerified(false);
		reCaptcha.current.reset();
		setFormData({
			email: '',
		});
	};

	//Redirect if logged in
	if (isAuthenticated) {
		return <Redirect to='/admin/dashboard' />;
	}

	return (
		<div className='row'>
			<div className='col-xs-10 col-xs-offset-1 col-sm-8 col-sm-offset-2 col-md-4 col-md-offset-4'>
				<div className='forgetpassword-panel panel panel-default'>
					<div className='panel-heading'>Forget Password </div>
					<div className='panel-body'>
						<form role='form' onSubmit={(e) => onSubmit(e)}>
							<div className='form-group'>
								<input
									className='form-control'
									placeholder='E-mail'
									name='email'
									value={email}
									onChange={(e) => onChange(e)}
									type='email'
									autofocus
								/>
							</div>
							<ReCAPTCHA
								ref={reCaptcha}
								sitekey='6LdT9D4bAAAAAP1hvSJarS3nDaxWwJY5fx7SgYtR'
								onChange={gonChange}
							/>
							<button type='submit' className='btn btn-primary btn-sm'>
								Submit
							</button>
						</form>
						<Link to='admin-login'>
							{' '}
							<h5> Go to Login </h5>
						</Link>
					</div>
				</div>
			</div>
			{/* /.col*/}
		</div>
	);
};

ForgetPassword.protoTypes = {
	forgetpassword: PropTypes.func.isRequired,
	isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { forgetpassword })(ForgetPassword);
