import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { slideradd } from '../../../actions/slider';
import { Link, withRouter } from 'react-router-dom';

const SliderAdd = ({ slideradd, history }) => {
	const [formData, setFormData] = useState({
		title: '',
		description: '',
		buttontitle: '',
		buttonlink: '',
		publish: '',
	});

	const { title, description, publish, buttontitle, buttonlink } = formData;

	const [file, setFile] = useState('');

	const fileHandle = (e) => {
		setFile(e.target.files[0]);
	};

	const onChange = (e) =>
		setFormData({ ...formData, [e.target.name]: e.target.value });

	const onCheckChange = (e) => {
		setFormData({ ...formData, publish: !publish });
	};

	const onSubmit = (e) => {
		e.preventDefault();
		const fd = new FormData();
		fd.append('image', file);
		fd.append('title', title);
		fd.append('description', description);
		fd.append('buttontitle', buttontitle);
		fd.append('buttonlink', buttonlink);
		fd.append('publish', publish);
		slideradd(fd, history);
	};

	return (
		<Fragment>
			<div className='col-sm-9 col-sm-offset-3 col-lg-10 col-lg-offset-2'>
				<div className='content-wrapper'>
					{/* Content Header (Page header) */}
					<section className='content-header'>
						<div className='container-fluid'>
							<div className='row mb-2'>
								<div className='col-sm-6'>
									<h1>Add Slider</h1>
								</div>
							</div>
						</div>
						{/* /.container-fluid */}
					</section>
					{/* Main content */}
					<section className='content'>
						<div className='container-fluid'>
							<div className='row'>
								{/* left column */}
								<div className='col-md-12'>
									{/* jquery validation */}
									<div>
										<div className='card-header'>
											<h3 className='card-title'>Fields with * are required</h3>
										</div>
										{/* /.card-header */}
										{/* form start */}
										<form onSubmit={(e) => onSubmit(e)}>
											<div className='card-body'>
												<div className='row'>
													<div className='col-sm-6'>
														{/* text input */}
														<div className='form-group'>
															<label htmlFor='title'>Title</label>
															<input
																type='text'
																name='title'
																value={title}
																onChange={(e) => onChange(e)}
																className='form-control'
																id='title'
																placeholder='Title for Slider'
															/>
														</div>
													</div>
												</div>

												<div className='row'>
													<div className='col-sm-6'>
														{/* text input */}
														<div className='form-group'>
															<label htmlFor='buttontitle'>Button Title </label>
															<input
																type='text'
																name='buttontitle'
																value={buttontitle}
																onChange={(e) => onChange(e)}
																className='form-control'
																id='buttontitle'
																placeholder='Enter Button Title'
															/>
														</div>
													</div>
												</div>

												<div className='row'>
													<div className='col-sm-6'>
														{/* text input */}
														<div className='form-group'>
															<label htmlFor='buttonlink'>Button Link </label>
															<input
																type='text'
																name='buttonlink'
																value={buttonlink}
																onChange={(e) => onChange(e)}
																className='form-control'
																id='buttonlink'
																placeholder='Enter Link to visit'
															/>
														</div>
													</div>
												</div>
												<div className='row'>
													<div className='col-sm-6'>
														{/* text input */}
														<div className='form-group'>
															<label htmlFor='description'>Description</label>
															<textarea
																name='description'
																value={description}
																onChange={(e) => onChange(e)}
																className='form-control'
																id='description'
																placeholder='Enter Description'
																rows='3'
															></textarea>
														</div>
													</div>
												</div>
												<div className='row'>
													<div className='col-sm-2'>
														{/* text input */}
														<div className='form-group'>
															<label htmlFor='publish'>Publish: </label>{' '}
															<input
																type='checkbox'
																name='publish'
																checked={publish}
																onChange={(e) => onCheckChange(e)}
															/>
														</div>
													</div>

													<div className='col-sm-2'>
														<label className='card-title text-center'>
															Choose Picture *
														</label>

														<input
															type='file'
															className='form-control-file'
															onChange={fileHandle}
														/>
													</div>
												</div>
											</div>
											<br />
											<div className='card-footer'>
												<button type='submit' className='btn btn-primary'>
													Submit
												</button>
												{'  '}
												<Link
													className=' btn btn-danger'
													to='../../../admin/sliders'
													role='button'
												>
													Cancel
												</Link>
											</div>
										</form>
									</div>
									{/* /.card */}
								</div>
								{/*/.col (left) */}
								{/* right column */}
								<div className='col-md-6'></div>
								{/*/.col (right) */}
							</div>
							{/* /.row */}
						</div>
						{/* /.container-fluid */}
					</section>
					{/* /.content */}
				</div>
			</div>
		</Fragment>
	);
};

SliderAdd.propTypes = {
	slideradd: PropTypes.func.isRequired,
};

export default connect(null, { slideradd })(withRouter(SliderAdd));
