import React, { Fragment } from 'react';
import Spinner from '../../layout/Spinner';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { logout } from '../../../actions/auth';

const Sidebar = ({ logout, auth: { user, loading } }) => {
	return (
		<Fragment>
			{loading ? (
				<Spinner />
			) : (
				<div id='sidebar-collapse' className='col-sm-3 col-lg-2 sidebar'>
					<div className='profile-sidebar'>
						<div className='profile-usertitle-name' align='center'>
							Welcome {user ? user.name : ''}
						</div>
					</div>
					<ul className='nav menu'>
						<li>
							<Link to='dashboard'>
								<em className='fa fa-dashboard'>&nbsp;</em> Dashboard
							</Link>
						</li>
						<li>
							<Link to='bookings'>
								<em className='fa fa-bar-chart'>&nbsp;</em> Booking{' '}
							</Link>
						</li>
						<li>
							<Link to='referrals'>
								<em className='fa fa-child'>&nbsp;</em> Referral{' '}
							</Link>
						</li>
						<li>
							<Link to='internet_plans'>
								<em className='fa fa-wifi'>&nbsp;</em> Internet Plans{' '}
							</Link>
						</li>
						{/* <li><a to="gallery.html"><em className="fa fa-envira">&nbsp;</em> Gallery </a></li> */}
						<li>
							<Link to='users'>
								<em className='fa fa-users'>&nbsp;</em> Users{' '}
							</Link>
						</li>
						<li>
							<Link to='sliders'>
								<em className='fa fa-photo'>&nbsp;</em> Slider{' '}
							</Link>
						</li>
						<li>
							<Link to='modals'>
								<em className='fa fa-object-group'>&nbsp;</em> Popup Modal{' '}
							</Link>
						</li>
						<li>
							<Link to='testimonials'>
								<em className='fa fa-comments'>&nbsp;</em> Testimonials{' '}
							</Link>
						</li>
						<li>
							<Link to='change_password'>
								<em className='fa fa-key'>&nbsp;</em> Change Password{' '}
							</Link>
						</li>
						<li>
							<a href='#' onClick={logout} className='nav-link'>
								<em className='fa fa-lock'>&nbsp;&nbsp;</em> Logout
							</a>
						</li>
						<li>
							<Link to='logs'>
								<em className='fa fa-stack-exchange'>&nbsp;</em> System Logs{' '}
							</Link>
						</li>
					</ul>
				</div>
			)}
		</Fragment>
	);
};

Sidebar.propTypes = {
	auth: PropTypes.object.isRequired,
	logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps, { logout })(Sidebar);
