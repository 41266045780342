import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useradd } from '../../../actions/user';
import { Link, withRouter } from 'react-router-dom';

const UserAdd = ({ useradd, auth: {user,loading}, history }) => {
	const [formData, setFormData] = useState({
		name: '',		
		password: '',
		email: '',
		developer:''
	});

	const { name,password, email,developer } = formData;

	const onChange = e =>
		setFormData({ ...formData, [e.target.name]: e.target.value });

	const onCheckChange = e => {
		setFormData({ ...formData, developer: !developer });
	};

	const onSubmit = e => {
		e.preventDefault();
		useradd(formData, history);
	};

	return (
		<Fragment>
             <div className="col-sm-9 col-sm-offset-3 col-lg-10 col-lg-offset-2">
			
			<div className='content-wrapper'>
				{/* Content Header (Page header) */}
				<section className='content-header'>
					<div className='container-fluid'>
						<div className='row mb-2'>
							<div className='col-sm-6'>
								<h1>Add User</h1>
							</div>
						</div>
					</div>
					{/* /.container-fluid */}
				</section>
				{/* Main content */}
				<section className='content'>
					<div className='container-fluid'>
						<div className='row'>
							{/* left column */}
							<div className='col-md-12'>
								{/* jquery validation */}
								<div >
									<div className='card-header'>
										<h3 className='card-title'>Fields with * are required</h3>
									</div>
									{/* /.card-header */}
									{/* form start */}
									<form onSubmit={e => onSubmit(e)}>
										<div className='card-body'>
											<div className='row'>
												<div className='col-sm-6'>
													{/* text input */}
													<div className='form-group'>
														<label htmlFor='fullname'>
															Full name *
														</label>
														<input
															type='text'
															name='name'
															value={name}
															onChange={e => onChange(e)}
															className='form-control'
															id='fullname'
															placeholder='Full Name'
															
														/>
													</div>
												</div>
												
											</div>

											<div className='row'>
												<div className='col-sm-6'>
													{/* text input */}
													<div className='form-group'>
														<label htmlFor='password'>
															Password *
														</label>
														<input
															type='password'
															name='password'
															value={password}
															onChange={e => onChange(e)}
															className='form-control'
															id='password'
															placeholder='Enter Password'
															
														/>
													</div>
												</div>
												
												
											</div>

											<div className='row'>
												<div className='col-sm-6'>
													{/* text input */}
													<div className='form-group'>
														<label htmlFor='email'>Email *</label>
														<input
															type='email'
															name='email'
															value={email}
															onChange={e => onChange(e)}
															className='form-control'
															id='email'
															placeholder='Enter Email ID'
														/>
													</div>
												</div>												
											</div>
											{user.developer ? <div className='row'>
												<div className='col-sm-6'>
													{/* text input */}
													
													<div className='form-group'>
													<label htmlFor='developer'>Developer: </label>{' '}
													<input
													type='checkbox'
													name='developer'
													checked={developer}
													onChange={e => onCheckChange(e)}
													/>
													</div>
												</div>
																								
											</div> : ''}
										</div>
										<div className='card-footer'>
											<button type='submit' className='btn btn-primary'>
												Submit
											</button>
											{'  '}
											<Link
												className=' btn btn-danger'
												to='../../../admin/users'
												role='button'
											>
												Cancel
											</Link>
										</div>
									</form>
								</div>
								{/* /.card */}
							</div>
							{/*/.col (left) */}
							{/* right column */}
							<div className='col-md-6'></div>
							{/*/.col (right) */}
						</div>
						{/* /.row */}
					</div>
					{/* /.container-fluid */}
				</section>
				{/* /.content */}
			</div>
            </div>
		</Fragment>
	);
};

UserAdd.propTypes = {
	auth: PropTypes.object.isRequired,
	useradd: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	auth: state.auth,
});

export default connect(mapStateToProps, { useradd })(withRouter(UserAdd));
