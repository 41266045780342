import React, { Fragment, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bookingadd } from '../../actions/booking';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';

let search = window.location.search;
let params = new URLSearchParams(search);

const Booking = ({ bookingadd, history }) => {
	const [formData, setFormData] = useState({
		name: '',
		mobile: '',
		email: '',
		plan: '',
		address: '',
		duration: '',
		price: '',
		message: '',
	});

	useEffect(() => {
		setFormData({
			plan: params.get('package'),
			duration: params.get('duration'),
			price: params.get('price'),
		});
	}, []);
	const reCaptcha = useRef();

	const [isVerified, setIsVerified] = useState(false);
	function gonChange(value) {
		setIsVerified(true);
	}

	const { name, mobile, email, plan, address, duration, price, message } =
		formData;

	const onChange = (e) =>
		setFormData({ ...formData, [e.target.name]: e.target.value });

	const onSubmit = (e) => {
		e.preventDefault();
		if (!isVerified) {
			toast.error('Invalid Captcha');
			return;
		}
		bookingadd(formData, history);
		setIsVerified(false);
		reCaptcha.current.reset();
	};

	return (
		<Fragment>
			<div>
				<div className='container'>
					<div>
						<h1 className='title_section'>Book a Plan !</h1>
						<h4 className='subtitle_section'>
							The one you find perfect for you
						</h4>
					</div>
				</div>
				<div className='container booking_page'>
					<div>
						<div className='col-md-6 booking_form'>
							<form onSubmit={(e) => onSubmit(e)}>
								<input
									className='form-control bform'
									type='text'
									readOnly
									required
									name='plan'
									onChange={(e) => onChange(e)}
									value={plan}
								/>
								<input
									className='form-control bform'
									type='text'
									readOnly
									required
									name='duration'
									onChange={(e) => onChange(e)}
									value={duration}
								/>
								<input
									className='form-control bform'
									type='text'
									readOnly
									required
									name='price'
									onChange={(e) => onChange(e)}
									value={'Rs ' + price}
								/>
								<input
									className='form-control bform'
									type='text'
									required
									name='name'
									value={name}
									onChange={(e) => onChange(e)}
									placeholder='Full Name'
								/>
								<input
									className='form-control bform'
									type='number'
									required
									name='mobile'
									value={mobile}
									onChange={(e) => onChange(e)}
									placeholder='Contact Number'
									minLength={10}
									inputMode='tel'
								/>
								<input
									className='form-control bform'
									type='email'
									name='email'
									value={email}
									onChange={(e) => onChange(e)}
									placeholder='E-mail (Optional)'
									inputMode='email'
								/>
								<input
									className='form-control bform'
									type='text'
									required
									name='address'
									value={address}
									onChange={(e) => onChange(e)}
									placeholder='Address'
								/>
								<textarea
									className='form-control bform'
									rows='3'
									name='message'
									value={message}
									onChange={(e) => onChange(e)}
									placeholder='Additional Details (Optional)'
								/>
								<ReCAPTCHA
									ref={reCaptcha}
                  					className='captcha_control'
									sitekey='6LdT9D4bAAAAAP1hvSJarS3nDaxWwJY5fx7SgYtR'
									onChange={gonChange}
								/>
								<br />
								<button
									className='btn btn-default btn-block btn-cstm'
									type='submit'
								>
									Book My Package
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

Booking.propTypes = {
	bookingadd: PropTypes.func.isRequired,
};

export default connect(null, { bookingadd })(Booking);
