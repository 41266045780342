import axios from 'axios';
import { GET_SLIDERS, SLIDER_ERROR,GET_SLIDER, SLIDER_UPDATED, SLIDER_DELETED } from './types';

// Toast
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure();


// get all the published sliders
export const getPsliders = () => async dispatch => {
	try {
		const res = await axios.get('/api/slider/');

		dispatch({
			type: GET_SLIDERS,
			payload: res.data,
		});
	} catch (err) {
		dispatch({
			type: SLIDER_ERROR,
			payload: { msg: err.response.statusText, status: err.response.status },
		});
	}
};

//create new slider
export const slideradd = (fd, history) => async dispatch => {
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	};

	try {
		await axios.post('/api/slider', fd, config);

		toast.success('Slider Successfully Added');
		history.push('/admin/sliders');
	} catch (err) {
		const errors = err.response.data.errors;

		if (errors) {
			errors.forEach(error => toast.error(error.msg));
		}

		dispatch({
			type: SLIDER_ERROR,
		});
	}
};


// get all the sliders
export const getsliders = () => async dispatch => {
	try {
		const res = await axios.get('/api/slider/all');

		dispatch({
			type: GET_SLIDERS,
			payload: res.data,
		});
	} catch (err) {
		dispatch({
			type: SLIDER_ERROR,
			payload: { msg: err.response.statusText, status: err.response.status },
		});
	}
};


//delete slider
export const sliderdelete = sliderId => async dispatch => {
	if (window.confirm('Are you sure? You want to delete ?')) {
		try {
			await axios.delete(`../api/slider/${sliderId}`);

			dispatch({
				type: SLIDER_DELETED,
				payload: sliderId,
			});

			toast.success('Slider Successfully Removed');
		} catch (err) {
			const errors = err.response.data.errors;

			if (errors) {
				errors.forEach(error => toast.error(error.msg));
			}

			dispatch({
				type: SLIDER_ERROR,
			});
		}
	}
};

// get slider by id
export const getsliderbyid = sliderid => async dispatch => {
	try {
		const res = await axios.get(`/api/slider/${sliderid}`);
		dispatch({
			type: GET_SLIDER,
			payload: res.data,
		});
	} catch (err) {
		dispatch({
			type: SLIDER_ERROR,
			payload: { msg: err.response.statusText, status: err.response.status },
		});
	}
};

//update slider by id
export const sliderupdate = (sliderid, fd, history) => async dispatch => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		};

		const res = await axios.put(`/api/slider/${sliderid}`, fd, config);

		dispatch({
			type: SLIDER_UPDATED,
			payload: res.data,
		});
		history.push('/admin/sliders');
		toast.success('Sliders Successfully Updated');
	} catch (err) {
		const errors = err.response.data.errors;

		if (errors) {
			errors.forEach(error => toast.error(error.msg));
		}

		dispatch({
			type: SLIDER_ERROR,
			payload: { msg: err.response.statusText, status: err.response.status },
		});
	}
};