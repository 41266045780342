import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import PackageItem from './PackageItem';
import SMEPackageItem from './SMEPackageItem';
import TvPackageItem from './TvPackageItem';
import { getPinternetplans } from '../../actions/internetplan';
import DedicatedForm from '../forms/DedicatedForm';

const Packages = ({
	getPinternetplans,
	internetplan: { internetplans, loading },
}) => {

  useEffect(() => {
		getPinternetplans();
	}, [getPinternetplans]);
  
    return loading ? (
      <Spinner />
    ) : (
        <div className="packages_airson">
  <div className="package_title">
    <h1 className="title_section">Choose Your Package</h1>
    <h4 className="subtitle_section">Internet Sararaaaaaaa !</h4></div>
  <p className="lead text-center">Hi there ! We have all the packages according to your need. Choose &gt; Book and Enjoy :)</p>
  <div>
    <ul className="nav nav-tabs nav-justified package_main">
      <li className="active"><a href="#homepackage" role="tab" data-toggle="tab">Home Package</a></li>
      <li><a href="#smepackage" role="tab" data-toggle="tab">SME Package</a></li>
      <li><a href="#dedicatedpackage" role="tab" data-toggle="tab">Dedicated Package</a></li>
     
    </ul>
    <div className="tab-content tab_inside">
      <div className="tab-pane fade in active" role="tabpanel" id="homepackage">
        <p className="text-center package_description">Enjoy monthly plans with fast internet speeds, unlimited data &amp; free devices. Work, learn, bond, and play without limits in all corners of your home with high-speed unlimited plans and free Huawei that eliminates dead spots.Enjoy
          limitless entertainment with a fast internet connection, and be inspired with premium content that you can access via IP TV. </p>
        <div>
          <ul className="nav nav-tabs nav-justified package_inside">
            <li className="active"><a href="#internet" role="tab" data-toggle="tab">Internet </a></li>
            <li><a href="#internetwithtv" role="tab" data-toggle="tab">Internet + Tv</a></li>
          </ul>
          <div className="tab-content">
            <div className="tab-pane active" role="tabpanel" id="internet">

              {!internetplans.length > 0 ? 'Plans not found': (<Fragment>
                {internetplans.filter(l => (!l.sme)).map(internetplan =>                
                        <PackageItem
							key={internetplan._id}
							internetplan={internetplan}
						/> )}			
          </Fragment>)}
              
            </div>
            <div className="tab-pane" role="tabpanel" id="internetwithtv">
            {!internetplans.length > 0 ? 'Plans not found': (<Fragment>
                {internetplans.filter(l => (!l.sme)).map(internetplan =>                
                        <TvPackageItem
							key={internetplan._id}
							internetplan={internetplan}
						/> )}	
            </Fragment>)}
            </div>
          </div>
        </div>
      </div>
      <div className="tab-pane fade" role="tabpanel" id="smepackage">
        <p className="text-center package_description">Powerful connectivity to keep you ahead of the curve.We want to help prepare your business for whatever comes next. That’s why we deliver fast, reliable Internet with enough bandwidth to power all your devices. </p>
        {!internetplans.length > 0 ? 'Plans not found': (<Fragment>
                {internetplans.filter(l => (l.sme)).map(internetplan =>                
                        <SMEPackageItem
							key={internetplan._id}
							internetplan={internetplan}
						/> )}			
          </Fragment>)}
      </div>
      
      <div className="tab-pane fade" role="tabpanel" id="dedicatedpackage">
        <p className="text-center package_description">Dedicated Internet Access is a business class connection providing the highest quality bandwidth, reliability, and performance available. Companies who require this consistent performance, and high Quality of Service (QoS) for applications,
          are typical customers of dedicated Internet service. </p>
          <DedicatedForm />
      </div>
    </div>
  </div>
  <script>
      var target = window.location.hash;
      $('a[href=' + target + ']').click();
    </script>
</div>

);
};


Packages.propTypes = {
	getPinternetplans: PropTypes.func.isRequired,
	internetplan: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	internetplan: state.internetplan,
});

export default connect(mapStateToProps, { getPinternetplans })(Packages);
