import {
	GET_TESTIMONIALS,
	GET_TESTIMONIAL,
	TESTIMONIAL_ERROR,
	TESTIMONIAL_DELETED,
	TESTIMONIAL_UPDATED,
} from '../actions/types';

const initialState = {
	testimonial: null,
	testimonials: [],
	loading: true,
	error: {},
};

function testimonial(state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case GET_TESTIMONIALS:
			return {
				...state,
				testimonials: payload,
				loading: false,
			};

		case GET_TESTIMONIAL:
		case TESTIMONIAL_UPDATED:
			return {
				...state,
				testimonial: payload,
				loading: false,
			};
		case TESTIMONIAL_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
			};
		case TESTIMONIAL_DELETED:
			return {
				...state,
				testimonials: state.testimonials.filter(
					(testimonial) => testimonial._id !== payload
				),
				loading: false,
			};
		default:
			return state;
	}
}

export default testimonial;
