import React, { useEffect } from 'react';
import { getCount } from '../../actions/booking';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const Dashboard = ({ getCount, count }) => {
	useEffect(() => {
		getCount();
	}, [getCount]);

	return (
		<div className='col-sm-9 col-sm-offset-3 col-lg-10 col-lg-offset-2'>
			<div className='row'>
				<h1>Welcome to Dashboard</h1>
			</div>
			<div className='row'>
				<div className='col-sm-4'>
					<div
						className='card text-white bg-info mb-4'
						style={{ maxWidth: '15rem;' }}
					>
						<div className='card-header'>
							<h3>Booking Pending</h3>
						</div>
						<div className='card-body text-center' style={{ marginTop: '30%' }}>
							<h1 className='card-title text-center'>{count.booking}</h1>
						</div>
					</div>
				</div>

				<div className='col-sm-4'>
					<div
						className='card text-white bg-info mb-4'
						style={{ maxWidth: '15rem;' }}
					>
						<div className='card-header'>
							<h3>Referral Pending</h3>
						</div>
						<div className='card-body text-center' style={{ marginTop: '30%' }}>
							<h1 className='card-title text-center'>{count.referral}</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

Dashboard.propTypes = {
	getCount: PropTypes.func.isRequired,
	count: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	count: state.count,
});

export default connect(mapStateToProps, {
	getCount,
})(Dashboard);
