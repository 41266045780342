import React from 'react'


const Topbar = () => {
    return (
        <nav className="navbarA navbarA-custom navbarA-fixed-top" role="navigation">
  <div className="container-fluid">
    <div className="navbarA-header">
      <a className="navbarA-brand" >Airson Admin</a>
    </div>
  </div>{/* /.container-fluid */}
</nav>

    )
}

export default Topbar
