import { GET_COUNT, COUNT_ERROR } from '../actions/types';

const initialState = {
	booking: null,
	referral: null,
	loading: true,
	error: {},
};

function count(state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case GET_COUNT:
			return {
				...state,
				booking: payload.Booking,
				referral: payload.Referral,
				loading: false,
			};
		case COUNT_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
			};
		default:
			return state;
	}
}

export default count;
