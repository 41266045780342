import axios from 'axios';
import {
	GET_USERS,
	GET_USER,
	USER_ERROR,
	CLEAR_PROFILE,
	LOGOUT_SUCCESS,
	USER_DELETED,
	USER_UPDATED,
} from './types';
// Toast
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

//Get User List
export const userlist = () => async dispatch => {
	try {
		const res = await axios.get('/api/users');

		dispatch({
			type: GET_USERS,
			payload: res.data,
		});
	} catch (err) {
		const errors = err.response.data.errors;

		if (errors) {
			errors.forEach(error => toast.error(error.msg));
		}

		dispatch({
			type: USER_ERROR,
		});
	}
};

//Add New User
export const useradd = (fd, history) => async dispatch => {
	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	};

	try {
		await axios.post('/api/users/', fd, config);

		toast.success('User Successfully Added');
		history.push('/admin/users');
	} catch (err) {
		const errors = err.response.data.errors;

		if (errors) {
			errors.forEach(error => toast.error(error.msg));
		}

		dispatch({
			type: USER_ERROR,
		});
	}
};

//Change Password
export const changepassword = (fd, history) => async dispatch => {
	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	};

	try {
		const res = await axios.post('/api/auth/changepassword', fd, config);
		toast.success(res);
		dispatch({
			type: CLEAR_PROFILE,
		});
		dispatch({
			type: LOGOUT_SUCCESS,
		});
	} catch (err) {
		const errors = err.response.data.errors;
		toast.error(err.response.data);

		if (errors) {
			errors.forEach(error => toast.error(error.msg));
		}

		dispatch({
			type: USER_ERROR,
		});
	}
};

//Forget password
export const forgetpassword = email => async dispatch => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		};

		const body = JSON.stringify({ email });

		const res = await axios.post('api/auth/forgetpassword', body, config);

		if (res.data.success) {
			toast.success(res.data.success);
		} else {
			toast.error(res.data.danger);
		}
	} catch (err) {
		const errors = err.response.data.errors;

		if (errors) {
			errors.forEach(error => toast.error(error.msg));
		}
		dispatch({
			type: USER_ERROR,
		});
	}
};

//Reset password
export const resetPassword = (newpassword, token) => async dispatch => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		};

		const body = JSON.stringify({ newpassword });

		const res = await axios.post(
			`/api/auth/resetpassword/${token}`,
			body,
			config
		);
		if (res.data.success) {
			toast.success(res.data.success);
		} else {
			toast.error(res.data);
		}
	} catch (err) {
		const errors = err.response.data.errors;
		console.log(errors);

		if (errors) {
			errors.forEach(error => toast.error(error.msg));
		} else {
			toast.error(err.response.data.msg);
		}

		dispatch({
			type: USER_ERROR,
		});
	}
};

//delete user
export const userdelete = userId => async dispatch => {
	if (window.confirm('Are you sure? You want to delete ?')) {
		try {
			await axios.delete(`../api/users/${userId}`);

			dispatch({
				type: USER_DELETED,
				payload: userId,
			});

			toast.success('User Successfully Removed');
		} catch (err) {
			const errors = err.response.data.errors;

			if (errors) {
				errors.forEach(error => toast.error(error.msg));
			}

			dispatch({
				type: USER_ERROR,
			});
		}
	}
};

// get user by id
export const getuserbyid = userid => async dispatch => {
	try {
		const res = await axios.get(`/api/users/${userid}`);
		dispatch({
			type: GET_USER,
			payload: res.data,
		});
	} catch (err) {
		dispatch({
			type: USER_ERROR,
			payload: { msg: err.response.statusText, status: err.response.status },
		});
	}
};

//update user by id
export const userupdate = (userid, formData, history) => async dispatch => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		};

		const res = await axios.put(`/api/users/${userid}`, formData, config);

		dispatch({
			type: USER_UPDATED,
			payload: res.data,
		});
		history.push('/admin/users');
		toast.success('User Successfully Updated');
	} catch (err) {
		const errors = err.response.data.errors;

		if (errors) {
			errors.forEach(error => toast.error(error.msg));
		}

		dispatch({
			type: USER_ERROR,
			payload: { msg: err.response.statusText, status: err.response.status },
		});
	}
};
