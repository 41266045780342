import React from 'react'
import Suggestion from  '../forms/Suggestion'

const support = () => {
    return (
       <div className="container-fluid about_us_main">
  <div>
    <div className="col-md-8">
      <h1 className="title_section">Support </h1>
      <h4 className="subtitle_section">Let us help you</h4>
      <h4 className="text-uppercase bg-info about_us_stitle">Office Support</h4>
      <p className="text-justify">Please call on the folllowing numbers if you need any urgent support.</p>
      <p className="text-justify">1660-61-52095 (Toll Free)</p>
      <h4 className="text-uppercase bg-info about_us_stitle">Technical Support</h4>
      <p>Here are the following numbers, you can contact to any number reliable according to your destination.</p>
      <ul>
        <li>1660-61-52095</li>
        <li>061-501230</li>
        <li>+977-9802859703</li>
        
      </ul>
      <h4 className="text-uppercase bg-info about_us_stitle">E-mail Support</h4>
      <p className="text-justify">If you need any email support please email us on our mail id : support@airson.com.np</p>
      <h4 className="text-uppercase bg-primary about_us_stitle"> </h4>
      <p className="text-justify"> </p>
      <p className="text-center">- <i className="fa fa-pagelines" /> -</p>
    </div>
    <div className="col-md-4">
      <Suggestion />      
    </div>
  </div>
</div>

    )
}

export default support