import { GET_PACKAGE } from '../actions/types';

const initialState = {
	ipackage: null,
	loading: true,
	error: {},
};

function ipackage(state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case GET_PACKAGE:
			return {
				...state,
				ipackage: payload,
				loading: false,
			};

		default:
			return state;
	}
}

export default ipackage;
