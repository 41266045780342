import {
	GET_BOOKINGS,
	BOOKING_ERROR,
	GET_BOOKING,
	BOOKING_DELETED,
	BOOKING_UPDATED,
} from '../actions/types';

const initialState = {
	booking: null,
	bookings: [],
	loading: true,
	error: {},
};

function booking(state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case GET_BOOKINGS:
			return {
				...state,
				bookings: payload,
				loading: false,
			};
		case GET_BOOKING:
			return {
				...state,
				booking: payload,
				loading: false,
			};
		case BOOKING_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
			};
		case BOOKING_UPDATED:
			return {
				...state,

				bookings: state.bookings.map((booking) =>
					booking._id === payload.bookingid
						? { ...booking, status: payload.book.status }
						: booking
				),
				loading: false,
			};
		case BOOKING_DELETED:
			return {
				...state,
				bookings: state.bookings.filter((booking) => booking._id !== payload),
				loading: false,
			};
		default:
			return state;
	}
}

export default booking;
